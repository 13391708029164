import {
  Box,
  TextField,
  Typography,
  List,
  ListItem,
  Grid,
  Button,
  Tabs,
  Tab,
  Input,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  Collapse,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize,
  Rating,
  OutlinedInput,
  ListItemText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Svg from "../assets/Svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { MuiOtpInput } from "mui-one-time-password-input";
import { CircularProgress } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import OTPInput from "react-otp-input";
export default {
  Box,
  TextField,
  Typography,
  List,
  ListItem,
  Link,
  PropTypes,
  Svg,
  ArrowForwardIcon,
  Grid,
  Button,
  Tabs,
  Tab,
  Input,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  Collapse,
  ExpandLess,
  ExpandMore,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ExpandMoreIcon,
  TextareaAutosize,
  Rating,
  OutlinedInput,
  ListItemText,
  Visibility,
  VisibilityOff,
  InputAdornment,
  IconButton,
  MuiOtpInput,
  CircularProgress,
  DownloadIcon,
  OTPInput,
};
