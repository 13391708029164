import React, { useEffect, useState } from "react";
import PagesIndex from "../../PagesIndex";
import Index from "../../Index";
import { useLocation } from "react-router-dom";

export default function AdminSidebar(props) {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openBlogs, setOpenBlogs] = useState(false);
  const handleClickAccount = () => {
    setOpen(!open);
  };
  const handleClickBlogs = () => {
    setOpenBlogs(!openBlogs);
  };

  return (
    <Index.Box className={`admin-sidebar-main`}>
      <Index.Box className="admin-sidebar-inner-main">
        <Index.Box className="admin-sidebar-logo-main">
          <img src={Index.Svg.logo} className="admin-sidebar-logo" alt="logo" />
          <Index.Button
            onClick={() => {
              props.setOpen(!props.open);
            }}
            className="sidebar-close-btn"
          >
            <img
              src={PagesIndex.Svg.closeicon}
              className="close-icon"
              alt="logo"
            />
          </Index.Button>
        </Index.Box>

        <Index.Box className="admin-sidebar-list-main">
          <Index.List className="admin-sidebar-list">
            <Index.ListItem className="admin-sidebar-listitem">
              <Index.Link
                to="/admin/dashboard"
                className={
                  location?.pathname.includes("/admin/dashboard")
                    ? "admin-sidebar-link active"
                    : "admin-sidebar-link"
                }
                onClick={() => {
                  window.innerWidth <= 1023 && props.setOpen(!props.open);
                }}
              >
                <img
                  src={PagesIndex.Svg.dashboard}
                  alt="sidebar icon"
                  className="admin-sidebar-icons"
                />
                Dashboard
              </Index.Link>
            </Index.ListItem>

            <Index.ListItem className="admin-sidebar-listitem">
              <Index.Link
                to="/admin/buyer-list"
                className={
                  location?.pathname.includes("/admin/buyer-list")
                    ? "admin-sidebar-link active"
                    : "admin-sidebar-link"
                }
                onClick={() => {
                  window.innerWidth <= 1023 && props.setOpen(!props.open);
                }}
              >
                <img
                  src={PagesIndex.Svg.userlist}
                  alt="sidebar icon"
                  className="admin-sidebar-icons"
                />
                Buyer List
              </Index.Link>
            </Index.ListItem>

            <Index.ListItem className="admin-sidebar-listitem">
              <Index.Link
                to="/admin/seller-list"
                className={
                  location?.pathname.includes("/admin/seller-list")
                    ? "admin-sidebar-link active"
                    : "admin-sidebar-link"
                }
                onClick={() => {
                  window.innerWidth <= 1023 && props.setOpen(!props.open);
                }}
              >
                <img
                  src={PagesIndex.Svg.userlist}
                  alt="sidebar icon"
                  className="admin-sidebar-icons"
                />
                Seller List
              </Index.Link>
            </Index.ListItem>

            {/* <Index.ListItem className="admin-sidebar-listitem">
              <Index.Link
                to="/admin/banner-management"
                className={
                  location?.pathname.includes("/admin/banner-management")
                    ? "admin-sidebar-link active"
                    : "admin-sidebar-link"
                }
                onClick={() => {
                  window.innerWidth <= 1023 && props.setOpen(!props.open);
                }}
              >
                <img
                  src={PagesIndex.Svg.userlist}
                  alt="sidebar icon"
                  className="admin-sidebar-icons"
                />
                Banner Management
              </Index.Link>
            </Index.ListItem>

            <Index.ListItem className="admin-sidebar-listitem">
              <Index.Link
                to="/admin/product-management"
                className={
                  location?.pathname.includes("/admin/product-management")
                    ? "admin-sidebar-link active"
                    : "admin-sidebar-link"
                }
                onClick={() => {
                  window.innerWidth <= 1023 && props.setOpen(!props.open);
                }}
              >
                <img
                  src={PagesIndex.Svg.userlist}
                  alt="sidebar icon"
                  className="admin-sidebar-icons"
                />
                Product Management
              </Index.Link>
            </Index.ListItem>

            <Index.ListItem className="admin-sidebar-listitem">
              <Index.Link
                to="/admin/order-management"
                className={
                  location?.pathname.includes("/admin/order-management")
                    ? "admin-sidebar-link active"
                    : "admin-sidebar-link"
                }
                onClick={() => {
                  window.innerWidth <= 1023 && props.setOpen(!props.open);
                }}
              >
                <img
                  src={PagesIndex.Svg.userlist}
                  alt="sidebar icon"
                  className="admin-sidebar-icons"
                />
                Order Management
              </Index.Link>
            </Index.ListItem>

            <Index.ListItem className="admin-sidebar-listitem">
              <Index.Link
                to="/admin/payment-management"
                className={
                  location?.pathname.includes("/admin/payment-management")
                    ? "admin-sidebar-link active"
                    : "admin-sidebar-link"
                }
                onClick={() => {
                  window.innerWidth <= 1023 && props.setOpen(!props.open);
                }}
              >
                <img
                  src={PagesIndex.Svg.userlist}
                  alt="sidebar icon"
                  className="admin-sidebar-icons"
                />
                Payment Management
              </Index.Link>
            </Index.ListItem>

            <Index.ListItem className="admin-sidebar-listitem">
              <Index.Link
                to="/admin/invoice-management"
                className={
                  location?.pathname.includes("/admin/invoice-management")
                    ? "admin-sidebar-link active"
                    : "admin-sidebar-link"
                }
                onClick={() => {
                  window.innerWidth <= 1023 && props.setOpen(!props.open);
                }}
              >
                <img
                  src={PagesIndex.Svg.userlist}
                  alt="sidebar icon"
                  className="admin-sidebar-icons"
                />
                Invoice Management
              </Index.Link>
            </Index.ListItem>

            <Index.ListItem className="admin-sidebar-listitem">
              <Index.Link
                to="/admin/query-management"
                className={
                  location?.pathname.includes("/admin/query-management")
                    ? "admin-sidebar-link active"
                    : "admin-sidebar-link"
                }
                onClick={() => {
                  window.innerWidth <= 1023 && props.setOpen(!props.open);
                }}
              >
                <img
                  src={PagesIndex.Svg.userlist}
                  alt="sidebar icon"
                  className="admin-sidebar-icons"
                />
                Query Management
              </Index.Link>
            </Index.ListItem>

            <Index.ListItem className="admin-sidebar-listitem">
              <Index.Link
                to="/admin/rating-review"
                className={
                  location?.pathname.includes("/admin/rating-review")
                    ? "admin-sidebar-link active"
                    : "admin-sidebar-link"
                }
                onClick={() => {
                  window.innerWidth <= 1023 && props.setOpen(!props.open);
                }}
              >
                <img
                  src={PagesIndex.Svg.userlist}
                  alt="sidebar icon"
                  className="admin-sidebar-icons"
                />
                Rating & Review
              </Index.Link>
            </Index.ListItem>

            <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
              <Index.Box sx={{ width: "100%" }}>
                <Index.Box
                  onClick={handleClickBlogs}
                  className="admin-sidebar-link"
                >
                  <img
                    src={PagesIndex.Svg.changepassword}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />{" "}
                  Blog Management
                  {openBlogs ? (
                    <Index.ExpandLess className="expandless-icon" />
                  ) : (
                    <Index.ExpandMore className="expandmore-icon" />
                  )}
                </Index.Box>
                <Index.Box className="submenu-main">
                  <Index.Collapse
                    in={openBlogs}
                    timeout="auto"
                    unmountOnExit
                    className="submenu-collapse"
                  >
                    <Index.List
                      component="div"
                      disablePadding
                      className="admin-sidebar-submenulist"
                    >
                      <Index.ListItem className="admin-sidebar-listitem">
                        <Index.Link
                          to="/admin/blogs-list"
                          className={
                            location?.pathname.includes("/admin/blogs-list")
                              ? "admin-sidebar-link active"
                              : "admin-sidebar-link"
                          }
                          onClick={() => {
                            window.innerWidth <= 1023 &&
                              props.setOpen(!props.open);
                          }}
                        >
                          Blog List
                        </Index.Link>
                      </Index.ListItem>
                      <Index.ListItem className="admin-sidebar-listitem">
                        <Index.Link
                          to="/admin/blogs-add-category"
                          className={
                            location?.pathname.includes(
                              "/admin/blogs-add-category"
                            )
                              ? "admin-sidebar-link active"
                              : "admin-sidebar-link"
                          }
                          onClick={() => {
                            window.innerWidth <= 1023 &&
                              props.setOpen(!props.open);
                          }}
                        >
                          Add Category
                        </Index.Link>
                      </Index.ListItem>
                      <Index.ListItem className="admin-sidebar-listitem">
                        <Index.Link
                          to="/admin/blogs-add-sub-category"
                          className={
                            location?.pathname.includes(
                              "/admin/blogs-add-sub-category"
                            )
                              ? "admin-sidebar-link active"
                              : "admin-sidebar-link"
                          }
                          onClick={() => {
                            window.innerWidth <= 1023 &&
                              props.setOpen(!props.open);
                          }}
                        >
                          Add Sub Category
                        </Index.Link>
                      </Index.ListItem>
                      <Index.ListItem className="admin-sidebar-listitem">
                        <Index.Link
                          to="/admin/blogs-add-tags"
                          className={
                            location?.pathname.includes("/admin/blogs-add-tags")
                              ? "admin-sidebar-link active"
                              : "admin-sidebar-link"
                          }
                          onClick={() => {
                            window.innerWidth <= 1023 &&
                              props.setOpen(!props.open);
                          }}
                        >
                          Add Tags
                        </Index.Link>
                      </Index.ListItem>
                    </Index.List>
                  </Index.Collapse>
                </Index.Box>
              </Index.Box>
            </Index.ListItem>

            <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
              <Index.Box sx={{ width: "100%" }}>
                <Index.Box
                  onClick={handleClickAccount}
                  className="admin-sidebar-link"
                >
                  <img
                    src={PagesIndex.Svg.changepassword}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />{" "}
                  CMS
                  {open ? (
                    <Index.ExpandLess className="expandless-icon" />
                  ) : (
                    <Index.ExpandMore className="expandmore-icon" />
                  )}
                </Index.Box>
                <Index.Box className="submenu-main">
                  <Index.Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className="submenu-collapse"
                  >
                    <Index.List
                      component="div"
                      disablePadding
                      className="admin-sidebar-submenulist"
                    >
                      <Index.ListItem className="admin-sidebar-listitem">
                        <Index.Link
                          to="/admin/admin-about"
                          className={
                            location?.pathname.includes("/admin/admin-about")
                              ? "admin-sidebar-link active"
                              : "admin-sidebar-link"
                          }
                          onClick={() => {
                            window.innerWidth <= 1023 &&
                              props.setOpen(!props.open);
                          }}
                        >
                          About
                        </Index.Link>
                      </Index.ListItem>
                      <Index.ListItem className="admin-sidebar-listitem">
                        <Index.Link
                          to="/admin/terms-conditions"
                          className={
                            location?.pathname.includes(
                              "/admin/terms-conditions"
                            )
                              ? "admin-sidebar-link active"
                              : "admin-sidebar-link"
                          }
                          onClick={() => {
                            window.innerWidth <= 1023 &&
                              props.setOpen(!props.open);
                          }}
                        >
                          Terms & Conditions
                        </Index.Link>
                      </Index.ListItem>
                      <Index.ListItem className="admin-sidebar-listitem">
                        <Index.Link
                          to="/admin/privacy-policy"
                          className={
                            location?.pathname.includes("/admin/privacy-policy")
                              ? "admin-sidebar-link active"
                              : "admin-sidebar-link"
                          }
                          onClick={() => {
                            window.innerWidth <= 1023 &&
                              props.setOpen(!props.open);
                          }}
                        >
                          Privacy Policy
                        </Index.Link>
                      </Index.ListItem>
                    </Index.List>
                  </Index.Collapse>
                </Index.Box>
              </Index.Box>
            </Index.ListItem> */}
          </Index.List>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
