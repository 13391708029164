import React, { useEffect, useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../../redux/features/slices/user/UserSlice";
import CommonModal from "../../common/commonModal/CommonModal";

export default function Header() {
  const { userToken, userData } = useSelector((state) => state.user);
  console.log(userToken, userData, 10);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(userToken, "userToken9");

  // LoginModal modal
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const handleOpenLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => setOpenLoginModal(false);

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const mobileNav = () => {
    if (!mobileNavOpen) {
      setMobileNavOpen(true);
      document.body.classList.add("mobile-nav-open");
    } else {
      setMobileNavOpen(false);
      document.body.classList.remove("mobile-nav-open");
    }
  };

  return (
    <Index.Box
      className={`header-main ${mobileNavOpen ? "open" : ""}`}
      component="header"
    >
      <Index.Box className="header-main-inner">
        <Index.Link to="/" className="header-cust-col header-logo-box">
          <img
            src={Index.Svg.logo}
            className="header-logo"
            alt="company logo"
          />
        </Index.Link>
        <Index.Box className="header-cust-col navigation">
          <Index.Box className="header-nav-ul">
            <Index.Box className="header-nav-li">
              <Index.Link to="/catalogue" className="header-nav-link">
                Categories
              </Index.Link>
            </Index.Box>
            <Index.Box className="header-nav-li">
              <Index.Link to="/about" className="header-nav-link">
                About us
              </Index.Link>
            </Index.Box>
            <Index.Box className="header-nav-li">
              <Index.Link to="#" className="header-nav-link">
                Financial Services{" "}
              </Index.Link>
            </Index.Box>
            <Index.Box className="header-nav-li">
              <Index.Link to="/blogs" className="header-nav-link">
                Blogs
              </Index.Link>
            </Index.Box>
            <Index.Box className="header-nav-li">
              <Index.Link to="/faq" className="header-nav-link">
                FAQ
              </Index.Link>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="header-cust-col header-right-main">
          <Index.Box className="header-search-box">
            <Index.TextField
              fullWidth
              className="form-control"
              placeholder="Search"
            />
            <img src={PagesIndex.Svg.search} className="header-search-icon" />
          </Index.Box>

          {/* {userData?.role == "buyer" && userToken ? (

            <Index.Box
              className="auth-wrape"
              onClick={() => navigate("/my-account")}
            >
              <img src={PagesIndex.Svg.customer} />
              <Index.Box className="header-login-text">My Account</Index.Box>
            </Index.Box>
          ) : (

            ""
          )} */}

          {/* {userData?.role == "seller" && userToken ? (

            <Index.Box
              className="auth-wrape"
              onClick={() => navigate("/seller/my-account")}
            >
              <img src={PagesIndex.Svg.customer} />
              <Index.Box className="header-login-text">My Account</Index.Box>
            </Index.Box>

          ) : (
            ""
          )} */}

          {/* {userToken ? (
            <>
              <Index.Box
                className="auth-wrape"
                onClick={() => dispatch(userLogout())}
              >
                <img src={PagesIndex.Svg.customer} />
                <Index.Box className="header-login-text">Log Out</Index.Box>
              </Index.Box>
            </>
          ) : ( */}
          <>
            <Index.Box className="auth-wrape" onClick={handleOpenLoginModal}>
              <img src={PagesIndex.Svg.customer} />
              <Index.Box className="header-login-text">
                Login/Register
              </Index.Box>
            </Index.Box>
          </>
          {/* )} */}
          {/* <Index.Box className="auth-wrape" onClick={handleOpenLoginModal}>
                <img src={PagesIndex.Svg.customer} />
                <Index.Box className="header-login-text">
                  Login/Register
                </Index.Box>
              </Index.Box> */}
          <Index.Button
            className={
              mobileNavOpen ? "mobile-menu-btn open" : "mobile-menu-btn"
            }
            onClick={mobileNav}
          >
            <img src={PagesIndex.Svg.burgermenu} alt="nav icon" />
          </Index.Button>
        </Index.Box>
      </Index.Box>
      {/* <PagesIndex.Login
        openLoginModal={openLoginModal}
        handleCloseLoginModal={handleCloseLoginModal}
        handleOpenLoginModal={handleOpenLoginModal}
      /> */}
      {openLoginModal && <CommonModal />}
    </Index.Box>
  );
}
