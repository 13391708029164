import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./productlisting.css";
import "./productlisting.responsive.css";
import { useNavigate } from "react-router-dom";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { userType } from "../../../../redux/features/slices/user/UserService";
import { Form, Formik } from "formik";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ProductListing() {
  const [getProduct, setGetProduct] = useState([]);
  console.log(getProduct, 25);

  const [brandData, setBrandData] = useState([]);
  const [thicknessData, setThicknessData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [gradsData, setGradsData] = useState([]);

  const [applicationData, setApplicationData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);

  const dispatch = useDispatch();
  // GotoVerification modal
  const [openGotoVerification, setOpenGotoVerification] = React.useState(false);
  const handleOpenGotoVerification = () => setOpenGotoVerification(true);
  const handleCloseGotoVerification = () => setOpenGotoVerification(false);

  const navigate = useNavigate();

  const getAllProduct = async () => {
    await DataService.get(Api.User.GET_ALL_PRODUCT)
      .then((res) => {
        console.log(res, 23);
        setGetProduct(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getType = async () => {
    await DataService.get(Api.User.GET_TYPES)
      .then((res) => {
        console.log(res, 23333);
        setTypeData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getBrand = async () => {
    await DataService.get(Api.User.GET_BRANDS)
      .then((res) => {
        setBrandData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getThickness = async () => {
    await DataService.get(Api.User.GET_THICKNESS)
      .then((res) => {
        setThicknessData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGrads = async () => {
    await DataService.get(Api.User.GET_GRADS)
      .then((res) => {
        setGradsData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getApplication = async () => {
    await DataService.get(Api.User.GET_APPLICATION)
      .then((res) => {
        setApplicationData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCategory = async () => {
    await DataService.get(Api.User.GET_PRODUCT_CATEGORY)
      .then((res) => {
        setCategoryData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSubCategory = async () => {
    await DataService.get(Api.User.GET_PRODUCT_SUB_CATEGORY)
      .then((res) => {
        setSubCategoryData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllProduct();
    getType();
    getBrand();
    getThickness();
    getGrads();
    getApplication();
    getCategory();
    getSubCategory();
  }, []);

  const initialValues = {
    type: [],
    thickness: [],
    brand: [],
    grand: [],
    application: [],
    productCategoryId: [],
    productSubCategoryId: [],
  };

  console.log(initialValues?.type);
  const handleFormSubmit = (values) => {
    console.log(values, "values69");
  };

  const formatData = (data) => {
    const prefix = data?.substring(0, 2);
    const lastTwoCharacters = data?.substring(data?.length - 2);
    const maskedData = `${prefix}...${lastTwoCharacters}`;
    return maskedData;
  };

  return (
    <>
      <Index.Box className="main-product-listing" component="main">
        <PagesIndex.BannerImage
          bannerImage={PagesIndex.Jpg.aboutBanner}
          bannerTitle="Steel"
          bannerContent="Lorem ipsum dolor sit amet consectetur."
        />
        <Index.Box className="product-listing-section">
          <Index.Box className="cus-container">
            <Index.Box className="product-listing-row">
              <Index.Box className="product-col-left">
                <Index.Box className="product-left-content-main">
                  <Index.Box className="product-listing-title-flex">
                    <Index.Typography
                      className="filter-title-text"
                      component="h2"
                      variant="h2"
                    >
                      Filters
                    </Index.Typography>
                    <Index.Typography
                      className="filter-clear-text"
                      component="h3"
                      variant="h3"
                    >
                      Clear filters
                    </Index.Typography>
                  </Index.Box>

                  {/* formik  start*/}
                  <Formik
                    initialValues={initialValues}
                    onSubmit={handleFormSubmit}
                  >
                    {({
                      handleSubmit,
                      values,
                      handleBlur,
                      handleChange,
                      errors,
                      touched,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        {console.log(values, "values699")}
                        <Index.Box className="product-drop-flex">
                          <Index.List className="product-drop-ul">
                            <Index.ListItem className="prodcut-drop-li">
                              <PagesIndex.CheckBoxDropdown
                                options={typeData}
                                handleChange={handleChange}
                                value={values.type}
                                name="type"
                                multiple
                                placeholder="Select Type"
                              />
                            </Index.ListItem>
                            <Index.ListItem className="prodcut-drop-li">
                              <PagesIndex.CheckBoxDropdown
                                options={brandData}
                                handleChange={handleChange}
                                value={values.brand}
                                name="brand"
                                multiple
                                placeholder="Select Brand"
                              />
                            </Index.ListItem>
                            <Index.ListItem className="prodcut-drop-li">
                              <PagesIndex.CheckBoxDropdown
                                options={thicknessData}
                                handleChange={handleChange}
                                value={values.thickness}
                                name="thickness"
                                multiple
                                placeholder="Select Thickness"
                              />
                            </Index.ListItem>
                            <Index.ListItem className="prodcut-drop-li">
                              <PagesIndex.CheckBoxDropdown
                                options={gradsData}
                                handleChange={handleChange}
                                value={values.grand}
                                name="grand"
                                multiple
                                placeholder="Select Grand"
                              />
                            </Index.ListItem>
                            <Index.ListItem className="prodcut-drop-li">
                              <PagesIndex.CheckBoxDropdown
                                options={applicationData}
                                handleChange={handleChange}
                                value={values.application}
                                name="application"
                                multiple
                                placeholder="Select Application"
                              />
                            </Index.ListItem>
                            <Index.ListItem className="prodcut-drop-li">
                              <PagesIndex.CheckBoxDropdown
                                options={categoryData}
                                handleChange={handleChange}
                                value={values.productCategoryId}
                                name="productCategoryId"
                                multiple
                                placeholder="Select Category"
                              />
                            </Index.ListItem>
                            <Index.ListItem className="prodcut-drop-li">
                              <PagesIndex.CheckBoxDropdown
                                options={subCategoryData}
                                handleChange={handleChange}
                                value={values.productSubCategoryId}
                                name="productSubCategoryId"
                                multiple
                                placeholder="Select Sub_category"
                              />
                            </Index.ListItem>
                          </Index.List>
                        </Index.Box>
                      </Form>
                    )}
                  </Formik>
                  {/* formik  end*/}

                  <Index.Box className="product-brand-main">
                    <Index.List className="product-brand-ul">
                      {getProduct?.map((item, index) => {
                        return (
                          <Index.ListItem className="prodcut-brand-li">
                            <PagesIndex.ProductCard
                              getAppProduct={item?.description}
                              brand={item?.brand?.map((row, index) => {
                                return <>{row?.name}</>;
                              })}
                              // image={console.log(item?.image[0], "item?.image")}
                              image={item?.image[0]}
                              singleProduct={item}
                            />
                          </Index.ListItem>
                        );
                      })}
                    </Index.List>

                    {/* <Index.ListItem className="prodcut-brand-li">
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className="prodcut-brand-li">
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className="prodcut-brand-li">
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className="prodcut-brand-li">
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className="prodcut-brand-li">
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className="prodcut-brand-li">
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className="prodcut-brand-li">
                        <PagesIndex.ProductCard />
                      </Index.ListItem>
                      <Index.ListItem className="prodcut-brand-li">
                        <PagesIndex.ProductCard />
                      </Index.ListItem> */}
                    {/* </Index.List> */}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="product-col-right">
                <Index.Box className="product-right-content-main">
                  <Index.Box className="product-added-card common-card">
                    <Index.Typography className="product-added-text">
                      Items added
                    </Index.Typography>
                    <Index.Box className="product-added-flex">
                      <Index.Typography
                        className="product-item-name"
                        component="h4"
                        variant="h4"
                      >
                        Round steel pipe 6”...
                      </Index.Typography>
                      <Index.Typography
                        className="product-item-remove-text"
                        component="p"
                        variant="p"
                      >
                        Remove
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="product-added-flex">
                      <Index.Typography
                        className="product-item-name"
                        component="h4"
                        variant="h4"
                      >
                        Stainless steel pipe 10”...
                      </Index.Typography>
                      <Index.Typography
                        className="product-item-remove-text"
                        component="p"
                        variant="p"
                      >
                        Remove
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="product-added-flex">
                      <Index.Typography
                        className="product-item-name"
                        component="h4"
                        variant="h4"
                      >
                        Raw steel pipe 9”...
                      </Index.Typography>
                      <Index.Typography
                        className="product-item-remove-text"
                        component="p"
                        variant="p"
                      >
                        Remove
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="product-added-flex">
                      <Index.Typography
                        className="product-item-name"
                        component="h4"
                        variant="h4"
                      >
                        Square steel pipe 12”...
                      </Index.Typography>
                      <Index.Typography
                        className="product-item-remove-text"
                        component="p"
                        variant="p"
                      >
                        Remove
                      </Index.Typography>
                    </Index.Box>
                    <PagesIndex.OrangeFillButton
                      btnLabel="Go to cart"
                      onClick={() => {
                        navigate("/my-cart");
                      }}
                      className="orange-fill-btn go-tocart-btn"
                    />
                  </Index.Box>
                  <Index.Box className="product-bulk-card common-card">
                    <Index.Box className="bulk-order-flex">
                      <Index.Typography
                        className="bulk-order-text"
                        component="h3"
                        variant="h3"
                      >
                        Bulk order
                      </Index.Typography>
                      <Index.Link className="download-sample-text">
                        <img
                          src={PagesIndex.Svg.downloadicon}
                          className="download-icon"
                          alt="download sample"
                        />
                        Download sample
                        <img
                          src={PagesIndex.Svg.downinfo}
                          className="down-info-icon"
                        />
                      </Index.Link>
                    </Index.Box>
                    <Index.Box className="choose-file-main">
                      <Index.TextField
                        className="choose-file-control"
                        type="file"
                        placeholder="Choose file"
                      />
                      <Index.Box className="choose-file-cust-main">
                        <Index.Box className="choose-file-cust-flex">
                          <Index.Box className="choose-file-left-main">
                            <Index.Typography
                              className="choose-file-text"
                              component="p"
                              variant="p"
                            >
                              Choose file
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="choose-file-right-main">
                            <Index.Typography
                              className="choose-browse-text"
                              component="p"
                              variant="p"
                            >
                              Browse file
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="upload-blue-main">
                      <Index.Button className="upload-blue-btn">
                        <img
                          src={PagesIndex.Svg.uploadfileicon}
                          alt="upload file"
                          className="upload-file-icon"
                        />
                        Upload
                      </Index.Button>
                    </Index.Box>
                    <Index.Typography
                      className="payment-term-text"
                      component="p"
                      variant="p"
                    >
                      Payment term
                    </Index.Typography>
                    <Index.Box className="payment-tem-drop-main">
                      <PagesIndex.DropDown />
                    </Index.Box>
                    <PagesIndex.GrayFillButton
                      btnLabel="Send enquiry"
                      className="gray-fill-btn gray-send-inquery-btn"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <PagesIndex.YouMayLike />
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openGotoVerification}
        onClose={handleCloseGotoVerification}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="goto-veryfication-modal-inner-main cus-scrollbar modal-hgt-scroll modal-common-style"
        >
          <Index.Box className="cus-scrollbar modal-hgt-scroll">
            <Index.Box className="modal-header">
              <img
                src={PagesIndex.Svg.closeicon}
                className="modal-close-icon"
                onClick={handleCloseGotoVerification}
              />
            </Index.Box>
            <Index.Box className="modal-logo-main">
              <img src={PagesIndex.Svg.logo} className="modal-logo" />
            </Index.Box>
            <Index.Typography
              className="veryfy-modal-para"
              component="p"
              variant="p"
            >
              Your account is not verified please verify your account
            </Index.Typography>
            <Index.Box className="modal-veryfy-btn-main">
              <PagesIndex.BlueFillButton
                btnLabel="Go to account verification"
                className="blue-fill-btn veryfy-modal-btn"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
