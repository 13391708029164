import React, { useEffect, useState } from "react";
import PagesIndex from "../../PagesIndex";
import Index from "../../Index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const CommonModal = () => {
  const [verifyId, setVerifyId] = useState(null);
  const [verifyEmail, setVerifyEmail] = useState("");
  const [userToken, setUserToken] = useState(null);
  const [open, setOpen] = useState(false);
  const [authStep, setAuthStep] = useState("Login");
  const [id, setId] = useState();

  //Open
  const handleOpen = () => {
    setOpen(true);
  };

  //Close
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddUser = () => {
    setAuthStep("Add_User");
  };

  const handleLogin = () => {
    setAuthStep("Login");
  };

  const handleForgot = () => {
    setAuthStep("forgot-password");
  };

  const handleOtpSend = () => {
    setAuthStep("Otp_Send");
  };

  const handleResetPassword = () => {
    setAuthStep("Reset_Password");
  };
  const handleVerifyOtp = (res) => {
    setVerifyId(res);
    setAuthStep("Otp_Verify");
  };
  const handdleCategorySelect = (res) => {
    setAuthStep("Catergory_Select");
    console.log(res, "hyyyyyy");
    setUserToken(res);
  };

  useEffect(() => {
    setOpen(true);
  }, []);
  return (
    <div>
      <Index.Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          // className="login-modal-inner-main auth-modal-inner modal-common-style"
          className={`modal-common-style ${
            authStep === "Add_User"
              ? "register-modal-inner-main auth-modal-inner"
              : "login-modal-inner-main auth-modal-inner"
          }`}
        >
          {authStep == "Login" && (
            <PagesIndex.Login
              handleClose={handleClose}
              handleAddUser={handleAddUser}
              onClose={handleClose}
              handleForgot={handleForgot}
              setAuthStep={setAuthStep}
            />
          )}

          {authStep === "forgot-password" && (
            <PagesIndex.ForgotPassword
              handleClose={handleClose}
              // handleAddUser={handleAddUser}
              onClose={handleClose}
              handleOtpSend={handleOtpSend}
              setAuthStep={setAuthStep}
              setVerifyEmail={setVerifyEmail}
              handleLogin={handleLogin}
              //   forgotEmail={email}
            />
          )}
          {authStep === "Otp_Send" && (
            <PagesIndex.UserOtp
              handleVerifyOtp={handleVerifyOtp}
              handleClose={handleClose}
              handleResetPassword={handleResetPassword}
              verifyEmail={verifyEmail}
            />
          )}
          {authStep === "Reset_Password" && (
            <PagesIndex.ResetPassword
              handleCaterSelect={handdleCategorySelect}
              handleVerifyOtp={handleVerifyOtp}
              handleClose={handleClose}
              verifyId={verifyId}
              handleLogin={handleLogin}
              verifyEmail={verifyEmail}
            />
          )}
          {authStep == "Add_User" && (
            <PagesIndex.Register
              handleClose={handleClose}
              handleAddUser={handleAddUser}
              onClose={handleClose}
              handleForgot={handleForgot}
              setAuthStep={setAuthStep}
              handleOpen={handleOpen}
              handleLogin={handleLogin}
            />
          )}
        </Index.Box>
      </Index.Modal>
    </div>
  );
};

export default CommonModal;
