import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./profile.css";
import { Form, Formik } from "formik";
import { styled } from "@mui/material/styles";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import * as Yup from "yup";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function Profile() {
  const { adminData } = useSelector((state) => state.admin);
  const [image, setImage] = useState(
    adminData ? adminData?.findAdmin?.profileImage : null
  );
  console.log(image, "adminData");
  const initialValues = {
    firstName: adminData ? adminData?.findAdmin?.firstName : "",
    lastName: adminData ? adminData?.findAdmin?.lastName : "",
    email: adminData ? adminData?.findAdmin?.email : "",
    phoneNumber: adminData ? adminData?.findAdmin?.phoneNumber : "",
    address: adminData ? adminData?.findAdmin?.address : "",
    profileImage: adminData ? adminData?.findAdmin?.profileImage : "",
  };

  const startSpace = /^(?!\s).*$/;
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[^\d]*$/, "Numbers are not allowed")
      .matches(
        // /^[a-zA-Z_][a-zA-Z_\s-]*[a-zA-Z_]$/,
        /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
        "Space allowed between characters"
      )
      .max(50, "Allowed only 50 characters")
      .required("Please enter your first name"),

    lastName: Yup.string()
      .matches(/^[^\d]*$/, "Numbers are not allowed")
      .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Space allowed between characters")
      .max(50, "Allowed only 50 characters")
      .required("Please enter your last name"),

    // address: Yup.string()
    //   .required("Please enter your address")
    //   .matches(/^[a-z0-9\s,'-]*$/i)
    //   .max(100, "Allowed only 100 characters"),
    address: Yup.string()
      .required("Please enter your address")
      .matches(startSpace, "Starting space not allow"),
    // .matches(/^[a-z0-9\s,'-]*$/i, "Spaces not allowed at the beginning or end")
    // .max(100, "Allowed only 100 characters"),

    email: Yup.string()
      .email("Please enter your valid email")
      .matches(
        /^[a-z][a-z0-9]*(?:\.[a-z]+)*@[a-z]+\.[a-z]{2,}$/,
        "Please enter your valid email"
      )
      .required("Please enter your email address"),

    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .required("Please enter your phone number"),

    profileImage: Yup.mixed()
      .required("Please select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        console.log(value, 302);
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type); //add
        }
      }),
  });

  const handleFormSubmit = async (values) => {
    console.log(values, "values17");
    const data = new FormData();
    // data.append("id", adminData?._id);
    data.append("firstName", values.firstName);
    data.append("lastName", values.lastName);
    data.append("email", values.email);
    data.append("phoneNumber", values.phoneNumber);
    data.append("address", values.address);
    data.append("profileImage", image);
    await DataService.post(
      `${Api.Admin.ADMIN_PROFILE_UPDATE}?id=${adminData?._id}`,
      data
    )
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <Index.Box className="profile-main">
      <Index.Box className="profile-header">Update Profile</Index.Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          setFieldValue,
          setTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            {console.log(values, "values")}
            <Index.Box className="profile-body">
              <Index.Box className="profile-img-box-wrapper">
                <Index.Box className="profile-img-box">
                  {console.log(values.profileImage, "values.profileImage")}
                  {image ? (
                    <img
                      // src={PagesIndex.Png.user}
                      src={
                        image
                          ? `http://194.233.77.156:3042/images/${image}`
                          : URL.createObjectURL(image)
                      }
                      className="profile-img"
                      alt="Profile"
                    />
                  ) : (
                    <img
                      src={PagesIndex.Png.user}
                      className="profile-img"
                      alt="Profile"
                    />
                  )}

                  <Index.Button component="label" variant="contained">
                    <img
                      src={PagesIndex.Svg.pen}
                      alt="edit icon"
                      className="edit-icon"
                      width="30"
                      height="30"
                    />
                    <VisuallyHiddenInput
                      type="file"
                      className="comman-form-control"
                      accept="image/*"
                      name="profileImage"
                      onChange={(event) => {
                        setFieldValue("profileImage", event.target.files[0]);
                        setImage(event.target.files[0]);
                      }}
                    />
                  </Index.Button>
                  {errors.profileImage && touched.profileImage && (
                    <p className="error-text">{errors.profileImage}</p>
                  )}
                </Index.Box>
              </Index.Box>
              <Index.Box className="common-grid-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 4 }}
                    className="login-row"
                  >
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          First Name
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <Index.TextField
                            className="comman-form-control"
                            placeholder="Please enter your first name"
                            type="text"
                            name="firstName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                          />
                        </Index.Box>
                        {errors.firstName && touched.firstName && (
                          <p className="error-text">{errors.firstName}</p>
                        )}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Last Name
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <Index.TextField
                            className="comman-form-control"
                            placeholder="Please enter your last name"
                            type="text"
                            name="lastName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                          />
                        </Index.Box>
                        {errors.lastName && touched.lastName && (
                          <p className="error-text">{errors.lastName}</p>
                        )}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Email
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <Index.TextField
                            className="comman-form-control"
                            placeholder="Please enter your email address"
                            type="text"
                            name="email"
                            onBlur={handleBlur}
                            // onChange={handleChange}
                            onChange={(e) => {
                              setFieldValue("email", e.target.value.trim());
                            }}
                            value={values.email}
                          />
                        </Index.Box>
                        {errors.email && touched.email && (
                          <p className="error-text">{errors.email}</p>
                        )}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Phone No.
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <Index.TextField
                            className="comman-form-control"
                            placeholder="Please enter your phone number"
                            type="text"
                            name="phoneNumber"
                            onBlur={handleBlur}
                            // onChange={handleChange}
                            onChange={(e) => {
                              if (e.target.value.length <= 10) {
                                setFieldValue(
                                  "phoneNumber",
                                  e.target.value.trim()
                                );
                              }
                            }}
                            value={values.phoneNumber}
                          />
                        </Index.Box>
                        {errors.phoneNumber && touched.phoneNumber && (
                          <p className="error-text">{errors.phoneNumber}</p>
                        )}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Address
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <Index.Box className="textarea-common-control">
                            <Index.TextareaAutosize
                              className="common-textarea-autosize"
                              minRows={5}
                              placeholder="Please enter your address"
                              type="text"
                              name="address"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.address}
                            />
                          </Index.Box>
                        </Index.Box>
                        {errors.address && touched.address && (
                          <p className="error-text">{errors.address}</p>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="admin-footer-btn-box">
                <PagesIndex.BlueFillButton
                  className="view-order-btn blue-fill-btn"
                  btnLabel="Update"
                  type="submit"
                />
              </Index.Box>
            </Index.Box>
          </Form>
        )}
      </Formik>
    </Index.Box>
  );
}
