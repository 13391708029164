import React, { useEffect, useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function ViewSeller(props) {
  const { openViewSellerModal, handleCloseViewSellerModal, rows } = props;
  const { userData } = useSelector((state) => state.user);
  console.log(userData, 20);
  // tab
  const [value, setValue] = React.useState("one");
  const [singleData, setSingleData] = useState({});
  const [image, setImage] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSingleSeller = async () => {
    await DataService.get(`${Api.Admin.GET_SINGLE_SELLER}/${rows?._id}`)
      .then((res) => {
        console.log(res?.data?.data, 333);
        setSingleData(res?.data?.data);
      })
      .catch((error) => {
        console.log((error) => {
          console.log(error, 366);
        });
      });
  };
  useEffect(() => {
    getSingleSeller();
  }, [rows]);

  return (
    <>
      <Index.Modal
        open={openViewSellerModal}
        onClose={handleCloseViewSellerModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="register-modal-inner-main auth-modal-inner modal-common-style"
        >
          <Index.Box className=" cus-scrollbar modal-hgt-scroll">
            <Index.Box className="modal-header">
              <Index.Typography className="auth-modal-title">
                Seller Details
              </Index.Typography>

              <img
                src={PagesIndex.Svg.closeicon}
                className="modal-close-icon"
                onClick={handleCloseViewSellerModal}
              />
            </Index.Box>
            <Index.Box sx={{ width: "100%" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                defaul
              >
                {/* <Index.Tab value="one" label="Personal Details" /> */}
                <Index.Tab value="one" label="Bank Details" />
                <Index.Tab value="two" label="Business Details" />
                <Index.Tab value="three" label="Address Details " />
              </Index.Tabs>
            </Index.Box>
            {value == "one" ? (
              <>
                {" "}
                <Index.Box className="modal-body">
                  <Index.Box className="auth-content-main">
                    <Index.Box className="register-content-main">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                          className="login-row"
                        >
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Bank name :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.bankDetails
                                        ? singleData?.bankDetails?.bankName
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Account number :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {singleData?.bankDetails
                                        ? singleData?.bankDetails?.accountNumber
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      IFSC code :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.bankDetails
                                        ? singleData?.bankDetails?.ifscCode
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Cancelled Cheque :
                                    </Index.Box>
                                    <Index.Box
                                      sx={{ paddingLeft: 1, cursor: "pointer" }}
                                      onClick={(e) => {
                                        handleOpen();
                                        setImage(
                                          singleData?.bankDetails?.cancelCheque
                                        );
                                      }}
                                    >
                                      {" "}
                                      {singleData?.bankDetails
                                        ? singleData?.bankDetails?.cancelCheque
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              ""
            )}
            {/* {value == "one" ? (
              <>
                <Index.Box className="modal-body">
                  <Index.Box className="auth-content-main">
                    <Index.Box className="register-content-main">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                          className="login-row"
                        >
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Full Name :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData ? singleData?.fullName : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Phone Number :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {singleData
                                        ? singleData?.phoneNumber
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Email Address :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData ? singleData?.email : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              ""
            )} */}
            {value == "two" ? (
              <>
                <Index.Box className="modal-body">
                  <Index.Box className="auth-content-main">
                    <Index.Box className="register-content-main">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                          className="login-row"
                        >
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      MSME :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.msme
                                        ? singleData?.msme?.msmeNumber
                                        : "-"}
                                    </Index.Box>
                                    <Index.Box className="view-seller-image">
                                      {singleData?.msme?.msmeImage?<>
                                      <Index.DownloadIcon
                                        onClick={(e) => {
                                          handleOpen();
                                          setImage(singleData?.msme?.msmeImage);
                                        }}
                                      />
                                      </>:''}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      PAN of the company :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {singleData?.pan
                                        ? singleData?.pan?.panNumber
                                        : "-"}
                                    </Index.Box>
                                    <Index.Box className="view-seller-image">
                                    {singleData?.msme?.msmeImage?<>
                                      <Index.DownloadIcon
                                        onClick={(e) => {
                                          handleOpen();
                                          setImage(singleData?.pan?.panImage);
                                        }}
                                      />
                                      </>:''}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      GST Certificate :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.gst
                                        ? singleData?.gst?.gstNumber
                                        : "-"}
                                    </Index.Box>
                                    <Index.Box className="view-seller-image">
                                    {singleData?.msme?.msmeImage?<>
                                      <Index.DownloadIcon
                                        onClick={(e) => {
                                          handleOpen();
                                          setImage(singleData?.gst?.gstImage);
                                        }}
                                      />
                                      </>:''}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Factory Lisence :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.factoryLisence
                                        ? singleData?.factoryLisence
                                            ?.factoryLisenceNumber
                                        : "-"}
                                    </Index.Box>
                                    <Index.Box className="view-seller-image">
                                    {singleData?.msme?.msmeImage?<>
                                      <Index.DownloadIcon
                                        onClick={(e) => {
                                          handleOpen();
                                          setImage(
                                            singleData?.factoryLisence
                                              ?.factoryLisenceImage
                                          );
                                        }}
                                      />
                                      </>:""}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      ISO Certificate :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.isoCertificate
                                        ? singleData?.isoCertificate
                                            ?.isoCertificateNumber
                                        : "-"}
                                    </Index.Box>
                                    <Index.Box className="view-seller-image">
                                    {singleData?.msme?.msmeImage?<>
                                      <Index.DownloadIcon
                                        onClick={(e) => {
                                          handleOpen();
                                          setImage(
                                            singleData?.isoCertificate
                                              ?.isoCertificateImage
                                          );
                                        }}
                                      />
                                      </>:''}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      API Certificate :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.apiCertificate
                                        ? singleData?.apiCertificate
                                            ?.apiCertificateNumber
                                        : "-"}
                                    </Index.Box>
                                    <Index.Box className="view-seller-image">
                                    {singleData?.msme?.msmeImage?<>
                                      <Index.DownloadIcon
                                        onClick={(e) => {
                                          handleOpen();
                                          setImage(
                                            singleData?.apiCertificate
                                              ?.apiCertificateImage
                                          );
                                        }}
                                      />
                                      </>:''}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              ""
            )}
            {value == "three" ? (
              <>
                <Index.Box className="modal-body">
                  <Index.Box className="auth-content-main">
                    <Index.Box className="register-content-main">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                          className="login-row"
                        >
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Area :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.address
                                        ? singleData?.address?.area
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      City :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {singleData?.address
                                        ? singleData?.address?.city
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Country :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.address
                                        ? singleData?.address?.country
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      FlatNo :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.address
                                        ? singleData?.address?.flatNo
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Landmark :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.address
                                        ? singleData?.address?.landmark
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      Pincode :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.address
                                        ? singleData?.address?.pincode
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xxs: "span 12",
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="auth-input-main">
                              <Index.Box className="comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  <Index.Box sx={{ display: "flex" }}>
                                    <Index.Box sx={{ fontWeight: 700 }}>
                                      {" "}
                                      State :
                                    </Index.Box>
                                    <Index.Box sx={{ paddingLeft: 1 }}>
                                      {" "}
                                      {singleData?.address
                                        ? singleData?.address?.state
                                        : "-"}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.FormHelperText>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              ""
            )}
            <Index.Box className="modal-auth-btn-main">
              <PagesIndex.OrangeFillButton
                btnLabel="Back"
                className="orange-fill-btn auth-modal-btn"
                onClick={() => {
                  handleCloseViewSellerModal();
                }}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      {/* image modal */}
      <div>
        <Index.Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Index.Box sx={style}>
            <Index.Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              <img
                src={`http://194.233.77.156:3042/images/${image}`}
                alt="GST Image"
                width={300}
                height={300}
              />
            </Index.Typography>
          </Index.Box>
        </Index.Modal>
      </div>
    </>
  );
}
