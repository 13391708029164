import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {  useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { styled } from "@mui/material/styles";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const AddCategory = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    title: "",
    description: "",
    image: null,
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .matches(/^[a-zA-Z\s-, ]+$/, "Accept only alphabetic and white space")
      .required("Please enter your title"),
    description: Yup.string()
      .required("Please enter description")
      .test("maxWords", "Description must be at most 100 words", (value) => {
        if (value) {
          const words = value.split(/\s+/).filter((word) => word.length > 0);
          return words.length <= 100;
        }
        return true; // Allow empty description
      }),
    // image: Yup.string().required("Please select image"),
    image: Yup.mixed()
      .nullable()
      .required("Please select image")
      .test(
        "FILE_FORMAT",
        "allow only jpg,jpeg,png,pdf file",
        (value) =>
          !value ||
          (["jpg", "jpeg", "png", "pdf"].includes(
            value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
          ) &&
            [
              "image/jpg",
              "image/jpeg",
              "image/png",
              "application/pdf",
            ].includes(value?.type))
      )
      .test(
        "fileSize",
        "File size too large, max file size is 2.048 Mb",
        (file) => {
          if (file) {
            return file.size <= 2048000;
          } else {
            return true;
          }
        }
      ),
  });
  const handleFormSubmit = async (values) => {
    console.log(values, 13);
    setIsLoading(true);
    const data = new FormData();
    data.append("title", values.title);
    data.append("description", values.description);
    data.append("categoryImage", values.image);
    await DataService.post(Api.Admin.CREATE_CATEGORY, data)
      .then((res) => {
        console.log(res?.data?.data, 108);
        setIsLoading(false);
        toast.success(res?.data?.message);

        navigate("/admin/blogs-list");
      })
      .catch((error) => {
        console.log(error, 64);
        toast.success(error?.response?.data?.message);
        setIsLoading(false);
      });
  };
  return (
    <>
      <Index.Box className="flex-all admin-title-box">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            className="admin-sub-title admin-buyer-title"
            component="h3"
            variant="h3"
          >
            Add Category
          </Index.Typography>
        </Index.Box>
      </Index.Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Index.Box className="common-grid-main">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                  className="login-row"
                >
                  <Index.Box
                    gridColumn={{
                      xxs: "span 12",
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Title
                        <span className="astric-sign">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="auth-input-main">
                        <Index.TextField
                          className="comman-form-control"
                          placeholder=""
                          type="text"
                          name="title"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.title}
                        />
                      </Index.Box>
                      {errors.title && touched.title && (
                        <p className="error-text">{errors.title}</p>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xxs: "span 12",
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Description
                        <span className="astric-sign">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="auth-input-main">
                        <CKEditor
                          editor={ClassicEditor}
                          name="description"
                          data={values.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            const strippedData = data.replace(
                              /<\/?[^>]+(>|$)/g,
                              ""
                            );
                            const words = strippedData.trim().split(/\s+/);
                            const limitedContent = words
                              .slice(0, 100)
                              .join(" ");

                            handleChange({
                              target: {
                                name: "description",
                                value: data,
                              },
                            });
                          }}
                        />
                      </Index.Box>
                      {errors.description && touched.description && (
                        <p className="error-text">{errors.description}</p>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xxs: "span 12",
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="comman-form-group ">
                      <Index.FormHelperText className="comman-form-label">
                        Upload your image
                        <span className="astric-sign">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="common-edit-image-box add-product">
                        {values?.image ? (
                          <Index.Box className="common-edit-image">
                            <img
                              src={
                                typeof values.image === "string"
                                  ? values.image
                                  : URL.createObjectURL(values.image)
                              }
                              className="product-img"
                              alt="product"
                            />
                            <img
                              src={PagesIndex.Svg.closeicon}
                              className="product-img-close"
                              onClick={() => setFieldValue("image", "")}
                            />
                          </Index.Box>
                        ) : (
                          <Index.Box className="upload-img-file-modal">
                            <Index.Button
                              component="label"
                              variant="contained"
                              className="upload-label-details"
                            >
                              <Index.Box className="upload-main-content">
                                <img
                                  src={PagesIndex.Png.cloud}
                                  className="upload-cloud"
                                  alt="cloud"
                                />
                              </Index.Box>
                              <Index.Typography className="browse-cloud-text">
                                Browse Files to upload
                              </Index.Typography>
                              <Index.Typography className="browse-cloud-accepted">
                                (accepted JPG & PNG only)
                              </Index.Typography>
                              <VisuallyHiddenInput
                                type="file"
                                className="comman-form-control"
                                name="image"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue("image", e.target.files[0]);
                                }}
                              />
                            </Index.Button>
                          </Index.Box>
                        )}
                      </Index.Box>
                      {errors.image && touched.image && (
                        <p className="error-text">{errors.image}</p>
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-footer-btn-box">
              <PagesIndex.BlueFillButton
                btnLabel="Submit"
                className="blue-fill-btn"
                type="submit"
              />
            </Index.Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddCategory;
