import React, { memo } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import { styled } from "@mui/material/styles";
// for custom checkbox design

// import Index from "../../Index";

// const BpIcon = styled('span')(({ theme }) => ({
//       borderRadius: 2,
//       border: "1px solid #333333",
//       width: 18,
//       height: 18,
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       boxShadow: "none",
//       backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : 'transparent',
// }));

// const BpCheckedIcon = styled(BpIcon)({
//       backgroundColor: 'transparent',
//       '&:before': {
//             display: 'block',
//             width: 12,
//             height: 12,
//             backgroundImage:
//                   "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
//                   " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
//                   "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
//             content: '""',
//       },
//       'input:hover ~ &': {
//             backgroundColor: 'transparent',
//       },
// });

// // Inspired by blueprintjs
// function BpCheckbox(props) {
//       return (
//             <Index.Checkbox
//                   sx={{
//                         '&:hover': { bgcolor: 'transparent' },
//                   }}
//                   disableRipple
//                   color="default"
//                   checkedIcon={<BpCheckedIcon />}
//                   icon={<BpIcon />}
//                   inputProps={{ 'aria-label': 'Checkbox demo' }}
//                   {...props}
//             />
//       );
// }

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default memo(function CheckBoxDropdown({
  options,
  handleChange,
  value,
  name,
  placeholder
}) {
  // console.log(options, value, name, 76);
  console.log(options, value, name, 76);
  const [personName, setPersonName] = React.useState([]);

  // const handleChange = (event) => {
  //   console.log(event, 77);
  //   const {
  //     target: { value },
  //   } = event;
  //   setFieldValue(
  //     "dimention",
  //     event?.target?.value
  //     // On autofill we get a stringified value.
  //     // typeof value === "string" ? value.split(",") : value
  //   );
  // };

  return (
    <>
      {/* <Index.Box className="cust-drodown-outer-main">
                        <Index.Box className="cust-dropdown-main cust-dropdown-bg">
                              <Index.Box className="cust-dropdown-button-main">
                                    Type
                                    <span className='cust-dropdown-arrow'>
                                          <img src={PagesIndex.Svg.dropdownarrow} className='cust-dropdown-arrow-icon' />
                                    </span>
                              </Index.Box>
                        </Index.Box>
                        <Index.Box className="cust-dropdown-inner-main cust-dropdown-bg">
                              <Index.List className="cust-dropdown-ul">
                                    <Index.ListItem className="cust-dropdown-li">
                                          <Index.Box className="cust-dropdown-check-flex">
                                                <Index.Box className="checkbox-main">
                                                      <BpCheckbox />
                                                      <Index.Typography className="checkbox-lable">Thickness 1</Index.Typography>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.ListItem>
                                    <Index.ListItem className="cust-dropdown-li">
                                          <Index.Box className="cust-dropdown-check-flex">
                                                <Index.Box className="checkbox-main">
                                                      <BpCheckbox />
                                                      <Index.Typography className="checkbox-lable">Thickness 2</Index.Typography>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.ListItem>
                                    <Index.ListItem className="cust-dropdown-li">
                                          <Index.Box className="cust-dropdown-check-flex">
                                                <Index.Box className="checkbox-main">
                                                      <BpCheckbox />
                                                      <Index.Typography className="checkbox-lable">Thickness 3</Index.Typography>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.ListItem>
                              </Index.List>
                        </Index.Box>
                  </Index.Box> */}

      <Index.FormControl
        sx={{ width: " 100%" }}
        className="cust-dropdown-inner-main cust-dropdown-bg"
      >
        <Index.Select
          labelId="demo-multiple-checkbox-label"
          className="cust-dropdown-ul"
          id="demo-multiple-checkbox"
          multiple
          value={value}
          name={name}
          onChange={handleChange}
          renderValue={(selected) =>
            options
              .filter((cat) => selected.includes(cat._id))
              .map((cat) => cat.name)
              .join(", ")||placeholder
          }
          displayEmpty
          MenuProps={{ PaperProps: { style: { maxHeight: 224, width: 250 } } }}
          label="hgfgfgfd"
          placeholder={placeholder}
        >
          {/* <Index.MenuItem disabled value="">
            <Index.ListItemText>Type</Index.ListItemText>
          </Index.MenuItem> */}
          {/* {value.length === 0 && (
            <Index.MenuItem disabled value="">
              <Index.ListItemText>Type</Index.ListItemText>
            </Index.MenuItem>
          )} */}
          {/* <Index.MenuItem disabled>
            <em>Select Income</em>
          </Index.MenuItem> */}
          {options?.map((variant) => (
            <Index.MenuItem key={variant?._id} value={variant?._id}>
              {console.log(variant.name, value, 219)}
              <Index.Checkbox
                checked={value?.some((item) => item === variant?._id)}
              />
              <Index.ListItemText primary={variant?.name} />
            </Index.MenuItem>
          ))}
        </Index.Select>
      </Index.FormControl>
    </>
  );
});
