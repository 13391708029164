import {
  Box,
  TextField,
  Typography,
  List,
  ListItem,
  Grid,
  Button,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Modal,
  Tab,
  Tabs,
  Input,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  TextareaAutosize,
  RadioGroup,
  Radio,
  Switch,
  styled,
  Menu,
  Collapse,
  Stack,
  Pagination,
  SwipeableDrawer,
  CircularProgress,
  Rating,
} from "@mui/material";
import { Link } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PropTypes from 'prop-types';
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import TablePagination from "@mui/material/TablePagination";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export default {
  Box,
  TextField,
  OutlinedInput,
  Typography,
  List,
  ListItem,
  Grid,
  Link,
  MenuItem,
  Select,
  FormControl,
  Button,
  FormHelperText,
  Modal,
  Tab,
  Tabs,
  Input,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  InputAdornment,
  IconButton,
  Visibility,
  VisibilityOff,
  FormControlLabel,
  Checkbox,
  TextareaAutosize,
  RadioGroup,
  Radio,
  Switch,
  styled,
  Menu,
  Collapse,
  Stack,
  Pagination,
  MuiOtpInput,
  SwipeableDrawer,
  CircularProgress,
  Rating,
  DeleteOutlineIcon,
  ClearIcon,
  CheckIcon,
  TablePagination,
  PropTypes,
  KeyboardBackspaceIcon
};
