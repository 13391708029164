import React from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import { useNavigate } from "react-router-dom";

export default function ProductCard({
  getAppProduct,
  brand,
  image,
  singleProduct,
}) {
  console.log(singleProduct, "singleProduct");

  const removeImageTags = (html) => {
    console.log(html, 31);
    return html?.replace(/<(table|figure|[^>]+)>/g, "");
  };

  const navigate = useNavigate();
  const productDetail = () => {
    navigate("/product-detail", { state: { row: singleProduct } });
  };
  return (
    <>
      {/* {getAppProduct?.map((item, index) => {
        return (
          <> */}
      <Index.Box className="brand-main">
        <Index.Box className="brand-img-main">
          {/* <Index.Link to="/product-detail" className="brnad-img-box"> */}
            <img
              src={
                image != undefined
                  ? `http://194.233.77.156:3042/images/${image}`
                  : PagesIndex.Png.brandimg
              }
              alt="brand"
              className="brand-img"
              onClick={productDetail}
            />
          {/* </Index.Link> */}
          <Index.Box className="brand-content-main">
            <Index.Box className="brand-flex">
              <Index.Typography className="" component="h3" variant="h3">
                <Index.Link to="/product-detail" className="brand-name">
                  Brand Name
                </Index.Link>
              </Index.Typography>
              <Index.Typography
                className="brand-estate-text"
                component="h6"
                variant="h6"
              >
                {brand ? brand : "-"}
              </Index.Typography>
            </Index.Box>
            <Index.Typography className="brand-para" component="p" variant="p">
              {/* Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit
                    amet{" "} */}

              {getAppProduct ? removeImageTags(getAppProduct) : "-"}
            </Index.Typography>
            <PagesIndex.BlueFillButton
              btnLabel="Add to cart"
              className="blue-fill-btn prodct-cart-btn"
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* </>
        );
      })} */}
    </>
  );
}
