import React, { useState } from "react";
import Index from "../../../../Index";
import PagesIndex from "../../../../PagesIndex";
import "./verifyaccountmodal.css";
import "./verifyaccountmodal.responsive.css";
import { Form, Formik } from "formik";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function VerifyAccountModal(props) {
  const location = useLocation();
  const id = location?.state?.id;
  const token = location?.state?.token;
  const role = location?.state?.role;

  const { openVerifyAccountModal, handleCloseVerifyAccountModal } = props;
  const { userToken, userData } = useSelector((state) => state.user);
  const [image, setImage] = useState("");
  const [value, setValue] = React.useState("buyer");
  console.log(userToken, userData, 30);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const initialValues = {
    role: "",
    id: "",
    msmeNumber: "",
    msmeImage: "",
    panNumber: "",
    panImage: "",
    gstNumber: "",
    gstImage: "",
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    cancelCheque: "",
  };

  const validationSchema = Yup.object().shape({
    bankName: Yup.string().required("Please enter your bank name"),
    accountNumber: Yup.string().required("Please enter your account number"),
    ifscCode: Yup.string().required("Please enter your ifsc code"),
    cancelCheque: Yup.mixed()
      .required("Please select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),

    msmeNumber: Yup.string().required(" enter your msme number"),

    msmeImage: Yup.mixed()
      .required(" select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),

    panNumber: Yup.string().required(" enter your pan number"),

    panImage: Yup.mixed()
      .required(" select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),

    gstNumber: Yup.string().required(" enter your gst number"),

    gstImage: Yup.mixed()
      .required(" select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file);
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),
  });

  const handleFormSubmit = async (values) => {
    console.log(values, 39);
    const data = new FormData();
    data.append("id", userData?._id);
    data.append("role", userData?.role);
    data.append("msmeNumber", values.msmeNumber);
    data.append("msmeImage", values.msmeImage);
    data.append("panNumber", values.panNumber);
    data.append("panImage", values.panImage);
    data.append("gstNumber", values.gstNumber);
    data.append("gstImage", values.gstImage);
    data.append("bankName", values.bankName);
    data.append("accountNumber", values.accountNumber);
    data.append("ifscCode", values.ifscCode);
    data.append("cancelCheque", values.cancelCheque);

    await DataService.post(Api.User.VERIFICATION_BUYER_SELLER, data)
      .then((res) => {
        console.log(res, 57);
        handleCloseVerifyAccountModal();
        toast.success(res?.data?.message);
      })
      .catch((error) => {
        console.log(error, 59);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <Index.Modal
        open={openVerifyAccountModal}
        onClose={handleCloseVerifyAccountModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="veryfy-modal-inner-main modal-common-style"
        >
          <Index.Box className="cus-scrollbar modal-hgt-scroll">
            <Index.Box className="modal-header">
              <Index.Typography
                className="modal-header-title"
                component="h3"
                variant="h3"
              >
                Verify your account
              </Index.Typography>
              <img
                src={PagesIndex.Svg.closeicon}
                className="modal-close-icon"
                onClick={handleCloseVerifyAccountModal}
              />
            </Index.Box>
            <Index.Box className="modal-body">
              <Index.Typography
                className="verify-para"
                component="p"
                variant="p"
              >
                Fill details & upload certificates below*
              </Index.Typography>

              <Formik
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                validationSchema={validationSchema}
              >
                {({
                  handleSubmit,
                  values,
                  handleBlur,
                  handleChange,
                  errors,
                  touched,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Index.Box className="verify-bussiness-main">
                      <Index.Typography
                        className="verify-bussiness-title"
                        component="h4"
                        variant="h4"
                      >
                        Business details
                      </Index.Typography>

                      <Index.Box className="grid-row verify-data-row">
                        <Index.Box className="grid-main">
                          <Index.Box className="verify-grid-row">
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  MSME<span className="astric-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="upload-file-with-text">
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder="Type MSME Number"
                                    type="text"
                                    name="msmeNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.msmeNumber}
                                  />

                                  <Index.TextField
                                    className="upload-file"
                                    type="file"
                                    name="msmeImage"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "msmeImage",
                                        e.target.files[0]
                                      );
                                    }}
                                  />

                                  <Index.Box className="upload-file-right-main">
                                    <Index.Typography
                                      className="upload-browse-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={PagesIndex.Svg.cloudupload}
                                        className="cloudupload-icons"
                                        alt="upload"
                                      />
                                      Upload
                                    </Index.Typography>
                                  </Index.Box>
                                  {/* <Index.Box className="">
                              <img src={PagesIndex.Svg.uploaddone} className='' alt="upload" />
                            </Index.Box> */}
                                </Index.Box>
                                {((errors.msmeNumber && touched.msmeNumber) ||
                                  (errors.msmeImage && touched.msmeImage)) && (
                                  <p className="error-text">
                                    Please
                                    {errors.msmeNumber &&
                                      touched.msmeNumber &&
                                      errors.msmeNumber}
                                    {errors.msmeImage &&
                                      touched.msmeImage &&
                                      errors.msmeImage}
                                  </p>
                                )}
                                {/* <Index.Box className="after-upload-flex">
                            <Index.Box className="after-upload-path-text" component="p" variant="p" ><img src={PagesIndex.Svg.descriptionicon} alt='discription' className='description-icon' />File 001.jpg</Index.Box>
                            <img src={PagesIndex.Svg.deleteicon} alt='delete' className='delete-icon' />
                          </Index.Box> */}
                                {values?.msmeImage && (
                                  <Index.Box className="after-upload-flex">
                                    <Index.Box
                                      className="after-upload-path-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={
                                          typeof values.msmeImage === "string"
                                            ? values.msmeImage
                                            : URL.createObjectURL(
                                                values.msmeImage
                                              )
                                        }
                                        alt="discription"
                                        className="description-icon"
                                      />
                                      File 001.jpg
                                    </Index.Box>
                                    <img
                                      src={PagesIndex.Svg.deleteicon}
                                      alt="delete"
                                      className="delete-icon"
                                      onClick={() =>
                                        setFieldValue("msmeImage", "")
                                      }
                                    />
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  PAN of the company
                                  <span className="astric-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="upload-file-with-text">
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder="Type PAN"
                                    type="text"
                                    name="panNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.panNumber}
                                  />

                                  <Index.TextField
                                    className="upload-file"
                                    type="file"
                                    name="panImage"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "panImage",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                  <Index.Box className="upload-file-right-main">
                                    <Index.Typography
                                      className="upload-browse-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={PagesIndex.Svg.cloudupload}
                                        className="cloudupload-icons"
                                        alt="upload"
                                      />
                                      Upload
                                    </Index.Typography>
                                  </Index.Box>
                                  {/* <Index.Box className="">
                              <img src={PagesIndex.Svg.uploaddone} className='' alt="upload" />
                            </Index.Box> */}
                                </Index.Box>
                                {((errors.panNumber && touched.panNumber) ||
                                  (errors.panImage && touched.panImage)) && (
                                  <p className="error-text">
                                    Please
                                    {errors.panNumber &&
                                      touched.panNumber &&
                                      errors.panNumber}
                                    {errors.panImage &&
                                      touched.panImage &&
                                      errors.panImage}
                                  </p>
                                )}
                                {/* <Index.Box className="after-upload-flex">
                            <Index.Box className="after-upload-path-text" component="p" variant="p" ><img src={PagesIndex.Svg.descriptionicon} alt='discription' className='description-icon' />File 001.jpg</Index.Box>
                            <img src={PagesIndex.Svg.deleteicon} alt='delete' className='delete-icon' />
                          </Index.Box> */}
                                {values?.panImage && (
                                  <Index.Box className="after-upload-flex">
                                    <Index.Box
                                      className="after-upload-path-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={
                                          typeof values.panImage === "string"
                                            ? values.panImage
                                            : URL.createObjectURL(
                                                values.panImage
                                              )
                                        }
                                        alt="discription"
                                        className="description-icon"
                                      />
                                      File 001.jpg
                                    </Index.Box>
                                    <img
                                      src={PagesIndex.Svg.deleteicon}
                                      alt="delete"
                                      className="delete-icon"
                                      onClick={() =>
                                        setFieldValue("panImage", "")
                                      }
                                    />
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  GST certificate
                                  <span className="astric-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="upload-file-with-text">
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder="Type GST Number"
                                    type="text"
                                    name="gstNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.gstNumber}
                                  />
                                  <Index.TextField
                                    className="upload-file"
                                    type="file"
                                    name="gstImage"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "gstImage",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                  <Index.Box className="upload-file-right-main">
                                    <Index.Typography
                                      className="upload-browse-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={PagesIndex.Svg.cloudupload}
                                        className="cloudupload-icons"
                                        alt="upload"
                                      />
                                      Upload
                                    </Index.Typography>
                                  </Index.Box>
                                  {/* <Index.Box className="">
                              <img src={PagesIndex.Svg.uploaddone} className='' alt="upload" />
                            </Index.Box> */}
                                </Index.Box>
                                {((errors.gstNumber && touched.gstNumber) ||
                                  (errors.gstImage && touched.gstImage)) && (
                                  <p className="error-text">
                                    Please
                                    {errors.gstNumber &&
                                      touched.gstNumber &&
                                      errors.gstNumber}
                                    {errors.gstImage &&
                                      touched.gstImage &&
                                      errors.gstImage}
                                  </p>
                                )}
                                {/* <Index.Box className="after-upload-flex">
                            <Index.Box className="after-upload-path-text" component="p" variant="p" ><img src={PagesIndex.Svg.descriptionicon} alt='discription' className='description-icon' />File 001.jpg</Index.Box>
                            <img src={PagesIndex.Svg.deleteicon} alt='delete' className='delete-icon' />
                          </Index.Box> */}
                                {values?.gstImage && (
                                  <Index.Box className="after-upload-flex">
                                    <Index.Box
                                      className="after-upload-path-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={
                                          typeof values.gstImage === "string"
                                            ? values.gstImage
                                            : URL.createObjectURL(
                                                values.gstImage
                                              )
                                        }
                                        alt="discription"
                                        className="description-icon"
                                      />
                                      File 001.jpg
                                    </Index.Box>
                                    <img
                                      src={PagesIndex.Svg.deleteicon}
                                      alt="delete"
                                      className="delete-icon"
                                      onClick={() =>
                                        setFieldValue("gstImage", "")
                                      }
                                    />
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="business-bank-section veryfy-bank-section">
                        <Index.Typography className="business-bank-title">
                          Bank details
                        </Index.Typography>
                        <Index.Box className="grid-main">
                          <Index.Box className="verify-grid-row">
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    Bank name
                                  </Index.FormHelperText>

                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder=""
                                    type="text"
                                    name="bankName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.bankName}
                                  />
                                  {errors.bankName && touched.bankName && (
                                    <p className="error-text">
                                      {errors.bankName}
                                    </p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    Account number
                                  </Index.FormHelperText>

                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder=""
                                    type="text"
                                    name="accountNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.accountNumber}
                                  />
                                  {errors.accountNumber &&
                                    touched.accountNumber && (
                                      <p className="error-text">
                                        {errors.accountNumber}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    IFSC code
                                  </Index.FormHelperText>

                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder=""
                                    type="text"
                                    name="ifscCode"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.ifscCode}
                                  />
                                  {errors.ifscCode && touched.ifscCode && (
                                    <p className="error-text">
                                      {errors.ifscCode}
                                    </p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  Cancelled cheque
                                </Index.FormHelperText>
                                <Index.Box className="upload-file-main">
                                  <Index.TextField
                                    className="upload-file-control"
                                    placeholder="Choose file"
                                    type="file"
                                    name="cancelCheque"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "cancelCheque",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                  {errors.cancelCheque &&
                                    touched.cancelCheque && (
                                      <p className="error-text">
                                        {errors.cancelCheque}
                                      </p>
                                    )}
                                  <Index.Box className="upload-file-cust-main">
                                    <Index.Box className="upload-file-cust-flex">
                                      <Index.Box className="upload-file-left-main">
                                        <Index.Typography
                                          className="upload-file-text"
                                          component="p"
                                          variant="p"
                                        >
                                          Choose file
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="upload-file-right-main">
                                        <Index.Typography
                                          className="upload-browse-text"
                                          component="p"
                                          variant="p"
                                        >
                                          <img
                                            src={PagesIndex.Svg.cloudupload}
                                            className="cloudupload-icons"
                                            alt="upload"
                                          />
                                          Upload
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="modal-auth-btn-main modal-verfy-bt-main">
                        <PagesIndex.OrangeFillButton
                          btnLabel="Verify"
                          className="orange-fill-btn verfy-modal-btn"
                          type="submit"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Form>
                )}
              </Formik>
            </Index.Box>
            {/* <Index.Box className="modal-footer-main"></Index.Box> */}
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
