import React, { useState } from "react";
import Index from "../../../../Index";
import PagesIndex from "../../../../PagesIndex";
import "./sellerVerifyAccountModal.css";
import { styled } from "@mui/material/styles";
import { Form, Formik } from "formik";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function SellerVerifyAccountModal(props) {
  const { openSellerVerifyAccountModal, handleCloseSellerVerifyAccountModal } =
    props;
  const { userData } = useSelector((state) => state.user);
  const [imageCataLogueImage, setImageCataLogueImage] = useState([]);
  console.log(imageCataLogueImage, 388);

  const handleImageDelete = (index) => {
    const updatedImages = [...imageCataLogueImage];
    updatedImages.splice(index, 1);
    setImageCataLogueImage(updatedImages);
  };

  const initialValues = {
    msmeNumber: "",
    msmeImage: "",
    panNumber: "",
    panImage: "",
    gstNumber: "",
    gstImage: "",

    bankName: "",

    accountNumber: "",

    ifscCode: "",

    cancelCheque: "",

    factoryLisenceNumber: "",
    factoryLisenceImage: "",

    isoCertificateNumber: "",
    isoCertificateImage: "",

    bisdLisenceNumber: "",
    bisdLisenceImage: "",

    apiCertificateNumber: "",
    apiCertificateImage: "",
    cataLogueImage: [],
  };

  const validationSchema = Yup.object().shape({
    bankName: Yup.string().required("Please enter your bank name"),
    accountNumber: Yup.string().required("Please enter your account number"),
    ifscCode: Yup.string().required("Please enter your ifsc code"),
    cataLogueImage: Yup.mixed()
      .required("Please select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),
    apiCertificateImage: Yup.mixed()
      .required(" select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),
    apiCertificateNumber: Yup.string().required(
      " enter your api certificate number"
    ),

    apiCertificateImage: Yup.mixed()
      .required(" select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),
    cancelCheque: Yup.mixed()
      .required("Please select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),

    bisdLisenceNumber: Yup.string().required(" enter your bisdLisence number"),

    bisdLisenceImage: Yup.mixed()
      .required(" select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),

    isoCertificateNumber: Yup.string().required(
      " enter your iso certificate number"
    ),

    isoCertificateImage: Yup.mixed()
      .required(" select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),

    factoryLisenceNumber: Yup.string().required(
      " enter your factory lisence number"
    ),

    factoryLisenceImage: Yup.mixed()
      .required(" select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),

    msmeNumber: Yup.string().required(" enter your msme number"),

    msmeImage: Yup.mixed()
      .required(" select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),
    panNumber: Yup.string().required(" enter your pan number"),

    panImage: Yup.mixed()
      .required(" select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),

    gstNumber: Yup.string().required(" enter your gst number"),

    gstImage: Yup.mixed()
      .required(" select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),
    gstNumber: Yup.string().required(" enter your gst number"),

    gstImage: Yup.mixed()
      .required(" select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
        }
      }),
  });
  const handleFormSubmit = async (values) => {
    console.log(values, 399);
    const data = new FormData();
    data.append("id", userData?._id);
    data.append("role", userData?.role);
    data.append("msmeNumber", values.msmeNumber);
    data.append("msmeImage", values.msmeImage);
    data.append("panNumber", values.panNumber);
    data.append("panImage", values.panImage);
    data.append("gstNumber", values.gstNumber);
    data.append("gstImage", values.gstImage);
    data.append("factoryLisenceNumber", values.factoryLisenceNumber);
    data.append("factoryLisenceImage", values.factoryLisenceImage);
    data.append("isoCertificateNumber", values.isoCertificateNumber);
    data.append("isoCertificateImage", values.isoCertificateImage);
    data.append("bisdLisenceNumber", values.bisdLisenceNumber);
    data.append("bisdLisenceImage", values.bisdLisenceImage);
    data.append("apiCertificateNumber", values.apiCertificateNumber);
    data.append("apiCertificateImage", values.apiCertificateImage);
    data.append("bankName", values.bankName);
    data.append("accountNumber", values.accountNumber);
    data.append("ifscCode", values.ifscCode);
    data.append("cancelCheque", values.cancelCheque);
    values?.cataLogueImage?.map((e) => {
      data.append("cataLogueImage", e);
    });

    await DataService.post(Api.User.VERIFICATION_BUYER_SELLER, data)
      .then((res) => {
        console.log(res, 57);
        toast.success(res?.data?.message);
        handleCloseSellerVerifyAccountModal();
      })
      .catch((error) => {
        console.log(error, 59);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <Index.Modal
        open={openSellerVerifyAccountModal}
        onClose={handleCloseSellerVerifyAccountModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="veryfy-modal-inner-main modal-common-style"
        >
          <Index.Box className="cus-scrollbar modal-hgt-scroll">
            <Index.Box className="modal-header">
              <Index.Typography
                className="modal-header-title"
                component="h3"
                variant="h3"
              >
                Verify your account
              </Index.Typography>
              <img
                src={PagesIndex.Svg.closeicon}
                className="modal-close-icon"
                onClick={handleCloseSellerVerifyAccountModal}
              />
            </Index.Box>
            <Index.Box className="modal-body">
              <Index.Typography
                className="verify-para"
                component="p"
                variant="p"
              >
                Fill details & upload certificates below*
              </Index.Typography>
              <Formik
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                // validationSchema={validationSchema}
              >
                {({
                  handleSubmit,
                  values,
                  handleBlur,
                  handleChange,
                  errors,
                  touched,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {console.log(errors, "errors")}
                    <Index.Box className="verify-bussiness-main">
                      <Index.Typography
                        className="verify-bussiness-title"
                        component="h4"
                        variant="h4"
                      >
                        Business details
                      </Index.Typography>
                      <Index.Box className="grid-row verify-data-row">
                        <Index.Box className="grid-main">
                          <Index.Box className="verify-grid-row">
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  MSME<span className="astric-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="upload-file-with-text">
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder="Type MSME Number"
                                    type="text"
                                    name="msmeNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.msmeNumber}
                                  />

                                  <Index.TextField
                                    className="upload-file"
                                    type="file"
                                    name="msmeImage"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "msmeImage",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                  <Index.Box className="upload-file-right-main">
                                    <Index.Typography
                                      className="upload-browse-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={PagesIndex.Svg.cloudupload}
                                        className="cloudupload-icons"
                                        alt="upload"
                                      />
                                      Upload
                                    </Index.Typography>
                                  </Index.Box>
                                  {/* <Index.Box className="">
                              <img src={PagesIndex.Svg.uploaddone} className='' alt="upload" />
                            </Index.Box> */}
                                </Index.Box>

                                {((errors.msmeNumber && touched.msmeNumber) ||
                                  (errors.msmeImage && touched.msmeImage)) && (
                                  <p className="error-text">
                                    Please
                                    {errors.msmeNumber &&
                                      touched.msmeNumber &&
                                      errors.msmeNumber}
                                    {errors.msmeImage &&
                                      touched.msmeImage &&
                                      errors.msmeImage}
                                  </p>
                                )}
                                {values?.msmeImage && (
                                  <Index.Box className="after-upload-flex">
                                    <Index.Box
                                      className="after-upload-path-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={
                                          typeof values.msmeImage === "string"
                                            ? values.msmeImage
                                            : URL.createObjectURL(
                                                values.msmeImage
                                              )
                                        }
                                        alt="discription"
                                        className="description-icon"
                                      />
                                      File 001.jpg
                                    </Index.Box>
                                    <img
                                      src={PagesIndex.Svg.deleteicon}
                                      alt="delete"
                                      className="delete-icon"
                                      onClick={() =>
                                        setFieldValue("msmeImage", "")
                                      }
                                    />
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  PAN of the company
                                  <span className="astric-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="upload-file-with-text">
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder="Type PAN"
                                    type="text"
                                    name="panNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.panNumber}
                                  />
                                  <Index.TextField
                                    className="upload-file"
                                    type="file"
                                    name="panImage"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "panImage",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                  <Index.Box className="upload-file-right-main">
                                    <Index.Typography
                                      className="upload-browse-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={PagesIndex.Svg.cloudupload}
                                        className="cloudupload-icons"
                                        alt="upload"
                                      />
                                      Upload
                                    </Index.Typography>
                                  </Index.Box>
                                  {/* <Index.Box className="">
                              <img src={PagesIndex.Svg.uploaddone} className='' alt="upload" />
                            </Index.Box> */}
                                </Index.Box>

                                {((errors.panNumber && touched.panNumber) ||
                                  (errors.panImage && touched.panImage)) && (
                                  <p className="error-text">
                                    Please
                                    {errors.panNumber &&
                                      touched.panNumber &&
                                      errors.panNumber}
                                    {errors.panImage &&
                                      touched.panImage &&
                                      errors.panImage}
                                  </p>
                                )}
                                {values?.panImage && (
                                  <Index.Box className="after-upload-flex">
                                    <Index.Box
                                      className="after-upload-path-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={
                                          typeof values.panImage === "string"
                                            ? values.panImage
                                            : URL.createObjectURL(
                                                values.panImage
                                              )
                                        }
                                        alt="discription"
                                        className="description-icon"
                                      />
                                      File 001.jpg
                                    </Index.Box>
                                    <img
                                      src={PagesIndex.Svg.deleteicon}
                                      alt="delete"
                                      className="delete-icon"
                                      onClick={() =>
                                        setFieldValue("panImage", "")
                                      }
                                    />
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  GST certificate
                                  <span className="astric-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="upload-file-with-text">
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder="Type GST Number"
                                    type="text"
                                    name="gstNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.gstNumber}
                                  />
                                  <Index.TextField
                                    className="upload-file"
                                    type="file"
                                    name="gstImage"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "gstImage",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                  <Index.Box className="upload-file-right-main">
                                    <Index.Typography
                                      className="upload-browse-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={PagesIndex.Svg.cloudupload}
                                        className="cloudupload-icons"
                                        alt="upload"
                                      />
                                      Upload
                                    </Index.Typography>
                                  </Index.Box>
                                  {/* <Index.Box className="">
                              <img src={PagesIndex.Svg.uploaddone} className='' alt="upload" />
                            </Index.Box> */}
                                </Index.Box>

                                {((errors.gstNumber && touched.gstNumber) ||
                                  (errors.gstImage && touched.gstImage)) && (
                                  <p className="error-text">
                                    Please
                                    {errors.gstNumber &&
                                      touched.gstNumber &&
                                      errors.gstNumber}
                                    {errors.gstImage &&
                                      touched.gstImage &&
                                      errors.gstImage}
                                  </p>
                                )}
                                {/* <Index.Box className="after-upload-flex">
                                  <Index.Box
                                    className="after-upload-path-text"
                                    component="p"
                                    variant="p"
                                  >
                                    <img
                                      src={PagesIndex.Svg.descriptionicon}
                                      alt="discription"
                                      className="description-icon"
                                    />
                                    File 001.jpg
                                  </Index.Box>
                                  <img
                                    src={PagesIndex.Svg.deleteicon}
                                    alt="delete"
                                    className="delete-icon"
                                  />
                                </Index.Box> */}
                                {values?.gstImage && (
                                  <Index.Box className="after-upload-flex">
                                    <Index.Box
                                      className="after-upload-path-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={
                                          typeof values.gstImage === "string"
                                            ? values.gstImage
                                            : URL.createObjectURL(
                                                values.gstImage
                                              )
                                        }
                                        alt="discription"
                                        className="description-icon"
                                      />
                                      File 001.jpg
                                    </Index.Box>
                                    <img
                                      src={PagesIndex.Svg.deleteicon}
                                      alt="delete"
                                      className="delete-icon"
                                      onClick={() =>
                                        setFieldValue("gstImage", "")
                                      }
                                    />
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  Factory License
                                  <span className="astric-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="upload-file-with-text">
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder="Type Factory License"
                                    type="text"
                                    name="factoryLisenceNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.factoryLisenceNumber}
                                  />
                                  <Index.TextField
                                    className="upload-file"
                                    type="file"
                                    name="factoryLisenceImage"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "factoryLisenceImage",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                  <Index.Box className="upload-file-right-main">
                                    <Index.Typography
                                      className="upload-browse-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={PagesIndex.Svg.cloudupload}
                                        className="cloudupload-icons"
                                        alt="upload"
                                      />
                                      Upload
                                    </Index.Typography>
                                  </Index.Box>
                                  {/* <Index.Box className="">
                              <img src={PagesIndex.Svg.uploaddone} className='' alt="upload" />
                            </Index.Box> */}
                                </Index.Box>
                                {((errors.factoryLisenceNumber &&
                                  touched.factoryLisenceNumber) ||
                                  (errors.factoryLisenceImage &&
                                    touched.factoryLisenceImage)) && (
                                  <p className="error-text">
                                    Please
                                    {errors.factoryLisenceNumber &&
                                      touched.factoryLisenceNumber &&
                                      errors.factoryLisenceNumber}
                                    {errors.factoryLisenceImage &&
                                      touched.factoryLisenceImage &&
                                      errors.factoryLisenceImage}
                                  </p>
                                )}
                                {/* <Index.Box className="after-upload-flex">
                                  <Index.Box
                                    className="after-upload-path-text"
                                    component="p"
                                    variant="p"
                                  >
                                    <img
                                      src={PagesIndex.Svg.descriptionicon}
                                      alt="discription"
                                      className="description-icon"
                                    />
                                    File 001.jpg
                                  </Index.Box>
                                  <img
                                    src={PagesIndex.Svg.deleteicon}
                                    alt="delete"
                                    className="delete-icon"
                                  />
                                </Index.Box> */}
                                {values?.factoryLisenceImage && (
                                  <Index.Box className="after-upload-flex">
                                    <Index.Box
                                      className="after-upload-path-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={
                                          typeof values.factoryLisenceImage ===
                                          "string"
                                            ? values.factoryLisenceImage
                                            : URL.createObjectURL(
                                                values.factoryLisenceImage
                                              )
                                        }
                                        alt="discription"
                                        className="description-icon"
                                      />
                                      File 001.jpg
                                    </Index.Box>
                                    <img
                                      src={PagesIndex.Svg.deleteicon}
                                      alt="delete"
                                      className="delete-icon"
                                      onClick={() =>
                                        setFieldValue("factoryLisenceImage", "")
                                      }
                                    />
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  ISO certificate
                                  <span className="astric-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="upload-file-with-text">
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder="Type ISO Number"
                                    type="text"
                                    name="isoCertificateNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.isoCertificateNumber}
                                  />
                                  <Index.TextField
                                    className="upload-file"
                                    type="file"
                                    name="isoCertificateImage"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "isoCertificateImage",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                  <Index.Box className="upload-file-right-main">
                                    <Index.Typography
                                      className="upload-browse-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={PagesIndex.Svg.cloudupload}
                                        className="cloudupload-icons"
                                        alt="upload"
                                      />
                                      Upload
                                    </Index.Typography>
                                  </Index.Box>
                                  {/* <Index.Box className="">
                              <img src={PagesIndex.Svg.uploaddone} className='' alt="upload" />
                            </Index.Box> */}
                                </Index.Box>

                                {((errors.isoCertificateNumber &&
                                  touched.isoCertificateNumber) ||
                                  (errors.isoCertificateImage &&
                                    touched.isoCertificateImage)) && (
                                  <p className="error-text">
                                    Please
                                    {errors.isoCertificateNumber &&
                                      touched.isoCertificateNumber &&
                                      errors.isoCertificateNumber}
                                    {errors.isoCertificateImage &&
                                      touched.isoCertificateImage &&
                                      errors.isoCertificateImage}
                                  </p>
                                )}
                                {/* <Index.Box className="after-upload-flex">
                                  <Index.Box
                                    className="after-upload-path-text"
                                    component="p"
                                    variant="p"
                                  >
                                    <img
                                      src={PagesIndex.Svg.descriptionicon}
                                      alt="discription"
                                      className="description-icon"
                                    />
                                    File 001.jpg
                                  </Index.Box>
                                  <img
                                    src={PagesIndex.Svg.deleteicon}
                                    alt="delete"
                                    className="delete-icon"
                                  />
                                </Index.Box> */}
                                {values?.isoCertificateImage && (
                                  <Index.Box className="after-upload-flex">
                                    <Index.Box
                                      className="after-upload-path-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={
                                          typeof values.isoCertificateImage ===
                                          "string"
                                            ? values.isoCertificateImage
                                            : URL.createObjectURL(
                                                values.isoCertificateImage
                                              )
                                        }
                                        alt="discription"
                                        className="description-icon"
                                      />
                                      File 001.jpg
                                    </Index.Box>
                                    <img
                                      src={PagesIndex.Svg.deleteicon}
                                      alt="delete"
                                      className="delete-icon"
                                      onClick={() =>
                                        setFieldValue("isoCertificateImage", "")
                                      }
                                    />
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  BIS license
                                  <span className="astric-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="upload-file-with-text">
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder="Type BIS license Number"
                                    type="text"
                                    name="bisdLisenceNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.bisdLisenceNumber}
                                  />
                                  <Index.TextField
                                    className="upload-file"
                                    type="file"
                                    name="bisdLisenceImage"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "bisdLisenceImage",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                  <Index.Box className="upload-file-right-main">
                                    <Index.Typography
                                      className="upload-browse-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={PagesIndex.Svg.cloudupload}
                                        className="cloudupload-icons"
                                        alt="upload"
                                      />
                                      Upload
                                    </Index.Typography>
                                  </Index.Box>
                                  {/* <Index.Box className="">
                              <img src={PagesIndex.Svg.uploaddone} className='' alt="upload" />
                            </Index.Box> */}
                                </Index.Box>

                                {((errors.bisdLisenceNumber &&
                                  touched.bisdLisenceNumber) ||
                                  (errors.bisdLisenceImage &&
                                    touched.bisdLisenceImage)) && (
                                  <p className="error-text">
                                    Please
                                    {errors.bisdLisenceNumber &&
                                      touched.bisdLisenceNumber &&
                                      errors.bisdLisenceNumber}
                                    {errors.bisdLisenceImage &&
                                      touched.bisdLisenceImage &&
                                      errors.bisdLisenceImage}
                                  </p>
                                )}
                                {/* <Index.Box className="after-upload-flex">
                                  <Index.Box
                                    className="after-upload-path-text"
                                    component="p"
                                    variant="p"
                                  >
                                    <img
                                      src={PagesIndex.Svg.descriptionicon}
                                      alt="discription"
                                      className="description-icon"
                                    />
                                    File 001.jpg
                                  </Index.Box>
                                  <img
                                    src={PagesIndex.Svg.deleteicon}
                                    alt="delete"
                                    className="delete-icon"
                                  />
                                </Index.Box> */}
                                {values?.bisdLisenceImage && (
                                  <Index.Box className="after-upload-flex">
                                    <Index.Box
                                      className="after-upload-path-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={
                                          typeof values.bisdLisenceImage ===
                                          "string"
                                            ? values.bisdLisenceImage
                                            : URL.createObjectURL(
                                                values.bisdLisenceImage
                                              )
                                        }
                                        alt="discription"
                                        className="description-icon"
                                      />
                                      File 001.jpg
                                    </Index.Box>
                                    <img
                                      src={PagesIndex.Svg.deleteicon}
                                      alt="delete"
                                      className="delete-icon"
                                      onClick={() =>
                                        setFieldValue("bisdLisenceImage", "")
                                      }
                                    />
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  API certificate
                                  <span className="astric-sign">*</span>
                                </Index.FormHelperText>
                                <Index.Box className="upload-file-with-text">
                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder="Type API Number"
                                    type="text"
                                    name="apiCertificateNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.apiCertificateNumber}
                                  />
                                  <Index.TextField
                                    className="upload-file"
                                    type="file"
                                    name="apiCertificateImage"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "apiCertificateImage",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                  <Index.Box className="upload-file-right-main">
                                    <Index.Typography
                                      className="upload-browse-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={PagesIndex.Svg.cloudupload}
                                        className="cloudupload-icons"
                                        alt="upload"
                                      />
                                      Upload
                                    </Index.Typography>
                                  </Index.Box>
                                  {/* <Index.Box className="">
                              <img src={PagesIndex.Svg.uploaddone} className='' alt="upload" />
                            </Index.Box> */}
                                </Index.Box>
                                {((errors.apiCertificateNumber &&
                                  touched.apiCertificateNumber) ||
                                  (errors.apiCertificateImage &&
                                    touched.apiCertificateImage)) && (
                                  <p className="error-text">
                                    Please
                                    {errors.apiCertificateNumber &&
                                      touched.apiCertificateNumber &&
                                      errors.apiCertificateNumber}
                                    {errors.apiCertificateImage &&
                                      touched.apiCertificateImage &&
                                      errors.apiCertificateImage}
                                  </p>
                                )}
                                {/* <Index.Box className="after-upload-flex">
                                  <Index.Box
                                    className="after-upload-path-text"
                                    component="p"
                                    variant="p"
                                  >
                                    <img
                                      src={PagesIndex.Svg.descriptionicon}
                                      alt="discription"
                                      className="description-icon"
                                    />
                                    File 001.jpg
                                  </Index.Box>
                                  <img
                                    src={PagesIndex.Svg.deleteicon}
                                    alt="delete"
                                    className="delete-icon"
                                  />
                                </Index.Box> */}
                                {values?.apiCertificateImage && (
                                  <Index.Box className="after-upload-flex">
                                    <Index.Box
                                      className="after-upload-path-text"
                                      component="p"
                                      variant="p"
                                    >
                                      <img
                                        src={
                                          typeof values.apiCertificateImage ===
                                          "string"
                                            ? values.apiCertificateImage
                                            : URL.createObjectURL(
                                                values.apiCertificateImage
                                              )
                                        }
                                        alt="discription"
                                        className="description-icon"
                                      />
                                      File 001.jpg
                                    </Index.Box>
                                    <img
                                      src={PagesIndex.Svg.deleteicon}
                                      alt="delete"
                                      className="delete-icon"
                                      onClick={() =>
                                        setFieldValue("apiCertificateImage", "")
                                      }
                                    />
                                  </Index.Box>
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="business-bank-section veryfy-bank-section">
                        <Index.Typography className="business-bank-title">
                          Bank details
                        </Index.Typography>
                        <Index.Box className="grid-main">
                          <Index.Box className="verify-grid-row">
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    Bank name
                                  </Index.FormHelperText>

                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder=""
                                    type="text"
                                    name="bankName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.bankName}
                                  />
                                  {errors.bankName && touched.bankName && (
                                    <p className="error-text">
                                      {errors.bankName}
                                    </p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    Account number
                                  </Index.FormHelperText>

                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder=""
                                    type="text"
                                    name="accountNumber"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.accountNumber}
                                  />
                                  {errors.accountNumber &&
                                    touched.accountNumber && (
                                      <p className="error-text">
                                        {errors.accountNumber}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main">
                                <Index.Box className="comman-form-group">
                                  <Index.FormHelperText className="comman-form-label">
                                    IFSC code
                                  </Index.FormHelperText>

                                  <Index.TextField
                                    className="comman-form-control"
                                    placeholder=""
                                    type="text"
                                    name="ifscCode"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.ifscCode}
                                  />
                                  {errors.ifscCode && touched.ifscCode && (
                                    <p className="error-text">
                                      {errors.ifscCode}
                                    </p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="grid-column">
                              <Index.Box className="verify-input-main comman-form-group">
                                <Index.FormHelperText className="comman-form-label">
                                  Cancelled cheque
                                </Index.FormHelperText>
                                <Index.Box className="upload-file-main">
                                  <Index.TextField
                                    className="upload-file-control"
                                    placeholder="Choose file"
                                    type="file"
                                    name="cancelCheque"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "cancelCheque",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                  {errors.cancelCheque &&
                                    touched.cancelCheque && (
                                      <p className="error-text">
                                        {errors.cancelCheque}
                                      </p>
                                    )}
                                  <Index.Box className="upload-file-cust-main">
                                    <Index.Box className="upload-file-cust-flex">
                                      <Index.Box className="upload-file-left-main">
                                        <Index.Typography
                                          className="upload-file-text"
                                          component="p"
                                          variant="p"
                                        >
                                          Choose file
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="upload-file-right-main">
                                        <Index.Typography
                                          className="upload-browse-text"
                                          component="p"
                                          variant="p"
                                        >
                                          <img
                                            src={PagesIndex.Svg.cloudupload}
                                            className="cloudupload-icons"
                                            alt="upload"
                                          />
                                          Upload
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="upload-catalogue">
                        <Index.Typography className="upload-catalogue-title">
                          Upload catalogue
                        </Index.Typography>
                        <Index.Box className="upload-img-file-modal">
                          <Index.Button
                            component="label"
                            variant="contained"
                            className="upload-label-details"
                          >
                            <Index.Box className="upload-main-content">
                              <img
                                src={PagesIndex.Png.cloud}
                                className="upload-cloud"
                                alt="cloud"
                              ></img>
                            </Index.Box>
                            <Index.Typography className="browse-cloud-text">
                              Browse Files to upload
                            </Index.Typography>
                            <Index.Typography className="browse-cloud-accepted">
                              (accepted JPG, PNG & PDF only)
                            </Index.Typography>

                            <VisuallyHiddenInput
                              type="file"
                              accept="image/jpeg, image/jpg, image/png, image/svg+xml, video/*"
                              multiple
                              name="cataLogueImage"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                const files = e.target.files;
                                const filesArray = [];

                                for (let i = 0; i < files.length; i++) {
                                  const file = files[i];

                                  filesArray.push(file);
                                }

                                setFieldValue("cataLogueImage", filesArray);

                                setImageCataLogueImage(filesArray);
                              }}
                            />
                            {errors.cataLogueImage &&
                              touched.cataLogueImage && (
                                <p className="error-text">
                                  {errors.cataLogueImage}
                                </p>
                              )}
                          </Index.Button>
                        </Index.Box>
                        <Index.Box className="upload-mt-details">
                          {imageCataLogueImage?.map((row, index) => {
                            return (
                              <>
                                {console.log(row, 776)}
                                <Index.Box className="upload-document-show">
                                  <Index.Box className="upload-document-card">
                                    <Index.Box className="file-icon-name">
                                      <img
                                        src={PagesIndex.Png.descriptionupload}
                                        className="description-upload"
                                        alt="desc"
                                      ></img>
                                      <Index.Typography className="file-payment-name">
                                        {row?.name ? row?.name : "-"}
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Button
                                      className="btn-delete-file"
                                      disableRipple
                                    >
                                      <img
                                        src={PagesIndex.Png.deleteupload}
                                        className="delete-details"
                                        alt="delete"
                                        onClick={() => handleImageDelete(index)}
                                      ></img>
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </>
                            );
                          })}

                          {/* <Index.Box className="upload-document-show">
                            <Index.Box className="upload-document-card">
                              <Index.Box className="file-icon-name">
                                <img
                                  src={PagesIndex.Png.descriptionupload}
                                  className="description-upload"
                                  alt="desc"
                                ></img>
                                <Index.Typography className="file-payment-name">
                                  File 001 PO
                                </Index.Typography>
                              </Index.Box>
                              <Index.Button
                                className="btn-delete-file"
                                disableRipple
                              >
                                <img
                                  src={PagesIndex.Png.deleteupload}
                                  className="delete-details"
                                  alt="delete"
                                ></img>
                              </Index.Button>
                            </Index.Box>
                          </Index.Box> */}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="modal-auth-btn-main modal-verfy-bt-main">
                        <PagesIndex.OrangeFillButton
                          btnLabel="Verify"
                          className="orange-fill-btn verfy-modal-btn"
                          type="submit"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Form>
                )}
              </Formik>
            </Index.Box>
            {/* <Index.Box className="modal-footer-main"></Index.Box> */}
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
