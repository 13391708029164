import React from "react";
import Index from "../../../../Index";
import PagesIndex from "../../../../PagesIndex";
import "./sellerQuoteBeShared.css";

export default function SellerQuoteBeShared() {
  return (
    <Index.Box className="seller-quote-be-shared-main">
      <Index.TableContainer className="seller-quote-be-shared-table-container common-table-container cus-scrollbar">
        <Index.Table
          aria-label="simple table"
          className="seller-quote-be-shared-table common-table action-common-table"
        >
          <Index.TableHead className="seller-quote-be-shared-table-head common-table-head">
            <Index.TableRow className="seller-quote-be-shared-table-row common-table-row">
              <Index.TableCell
                className="seller-quote-be-shared-table-th common-table-th"
                width="18%"
                align="left"
              >
                Enquiry No.
              </Index.TableCell>
              <Index.TableCell
                className="seller-quote-be-shared-table-th common-table-th"
                width="22%"
                align="left"
              >
                Product Details
              </Index.TableCell>
              <Index.TableCell
                className="seller-quote-be-shared-table-th common-table-th"
                width="20%"
                align="center"
              >
                Qty
              </Index.TableCell>
              <Index.TableCell
                className="seller-quote-be-shared-table-th common-table-th"
                width="20%"
                align="center"
              >
                Date
              </Index.TableCell>
              <Index.TableCell
                className="seller-quote-be-shared-table-th common-table-th"
                width="20%"
                align="center"
              >
                Value
              </Index.TableCell>
            </Index.TableRow>
          </Index.TableHead>
          <Index.TableBody className="seller-quote-be-shared-table-body common-table-body">
            <Index.TableRow>
              <Index.TableCell
                width="100%"
                colSpan="5"
                sx={{ padding: 0 }}
                className="action-common-table-td"
              >
                <Index.Table>
                  <Index.TableRow className="seller-quote-be-shared-table-row common-table-row">
                    <Index.TableCell
                      className="seller-quote-be-shared-table-td common-table-td"
                      width="18%"
                      align="left"
                    >
                      <Index.Box className="enquiry-order">#1234</Index.Box>
                    </Index.TableCell>
                    <Index.TableCell
                      className="seller-quote-be-shared-table-td common-table-td"
                      width="22%"
                      align="left"
                    >
                      <Index.Box className="order-product-details">
                        <img
                          src={PagesIndex.Png.cart}
                          className="cart-order-img"
                          alt="cart-order"
                        ></img>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="cart-order-details"
                        >
                          Fastgear 24 inch 15mm Steel Black Wire Plumbing
                          Connection{" "}
                        </Index.Typography>
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell
                      className="seller-quote-be-shared-table-td common-table-td"
                      width="20%"
                      align="center"
                    >
                      <Index.Box className="seller-qty">
                        6 X 6 - 50 pipes
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell
                      className="seller-quote-be-shared-table-td common-table-td"
                      width="20%"
                      align="center"
                    >
                      <Index.Box className="seller-date">24/08/2023</Index.Box>
                    </Index.TableCell>
                    <Index.TableCell
                      className="seller-quote-be-shared-table-td common-table-td"
                      width="20%"
                      align="center"
                    >
                      <Index.Box className="comman-form-group insert-value">
                        <Index.TextField
                          className="comman-form-control"
                          placeholder="Insert value"
                        />
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell
                      className="seller-quote-be-shared-table-td common-table-td table-row-action-td"
                      width="100%"
                      colSpan="5"
                    >
                      <Index.Box className="table-row-action seller">
                        <Index.Box className="button-box">
                          <PagesIndex.OrangeFillButton
                            btnLabel="Send"
                            className="orange-fill-btn"
                          />
                          <PagesIndex.OrangeOutlineButton
                            btnLabel="Reject"
                            className="orange-outline-inner"
                          />
                        </Index.Box>
                        <Index.Button className="action-btn">
                          <img
                            src={PagesIndex.Svg.moreVert}
                            alt="action icon"
                          />
                        </Index.Button>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.Table>
              </Index.TableCell>
            </Index.TableRow>
            <Index.TableRow>
              <Index.TableCell
                width="100%"
                colSpan="5"
                sx={{ padding: 0 }}
                className="action-common-table-td"
              >
                <Index.Table>
                  <Index.TableRow className="seller-quote-be-shared-table-row common-table-row">
                    <Index.TableCell
                      className="seller-quote-be-shared-table-td common-table-td"
                      width="18%"
                      align="left"
                    >
                      <Index.Box className="enquiry-order">#1234</Index.Box>
                    </Index.TableCell>
                    <Index.TableCell
                      className="seller-quote-be-shared-table-td common-table-td"
                      width="22%"
                      align="left"
                    >
                      <Index.Box className="order-product-details">
                        <img
                          src={PagesIndex.Png.cart}
                          className="cart-order-img"
                          alt="cart-order"
                        ></img>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="cart-order-details"
                        >
                          Fastgear 24 inch 15mm Steel Black Wire Plumbing
                          Connection{" "}
                        </Index.Typography>
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell
                      className="seller-quote-be-shared-table-td common-table-td"
                      width="20%"
                      align="center"
                    >
                      <Index.Box className="seller-qty">
                        6 X 6 - 50 pipes
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell
                      className="seller-quote-be-shared-table-td common-table-td"
                      width="20%"
                      align="center"
                    >
                      <Index.Box className="seller-date">24/08/2023</Index.Box>
                    </Index.TableCell>
                    <Index.TableCell
                      className="seller-quote-be-shared-table-td common-table-td"
                      width="20%"
                      align="center"
                    >
                      <Index.Box className="comman-form-group insert-value">
                        <Index.TextField
                          className="comman-form-control"
                          placeholder="Insert value"
                        />
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell
                      className="seller-quote-be-shared-table-td common-table-td table-row-action-td"
                      width="100%"
                      colSpan="5"
                    >
                      <Index.Box className="table-row-action seller">
                        <Index.Box className="button-box">
                          <PagesIndex.OrangeFillButton
                            btnLabel="Send"
                            className="orange-fill-btn"
                          />
                          <PagesIndex.OrangeOutlineButton
                            btnLabel="Reject"
                            className="orange-outline-inner"
                          />
                        </Index.Box>
                        <Index.Button className="action-btn">
                          <img
                            src={PagesIndex.Svg.moreVert}
                            alt="action icon"
                          />
                        </Index.Button>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.Table>
              </Index.TableCell>
            </Index.TableRow>
          </Index.TableBody>
        </Index.Table>
      </Index.TableContainer>
    </Index.Box>
  );
}
