import React, { useEffect, useRef, useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import "./auth.css";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Api } from "../../../config/Api";
import DataService from "../../../config/DataService";
import { toast } from "react-toastify";
// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function UserOtp({
  openUserOtp,
  handleCloseUserOtp,
  email,
  handleCloseForgotUser,
  openLoginModal,
  handleOtpSend,
  // handleResetPassword,
  handleClose,
  verifyEmail,
  handleResetPassword,
}) {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState();
  const [otpError, setOtpError] = useState("");
  const [active, setActive] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  console.log(setIsDisable, "setIsDisable");

  const [openResetUser, setOpenResetUser] = useState(false);
  const handleOpenResetUser = () => setOpenResetUser(true);
  const handleCloseResetUser = () => setOpenResetUser(false);
  console.log(verifyEmail, "email39");
  const formikRef = useRef();

  let initialValues = {
    Otp: "",
  };
  const validationSchema = Yup.object().shape({
    Otp: Yup.string()
      .min(4, "Please enter valid OTP")
      .required("Please enter OTP"),
  });

  const handleChange = (newValue) => {
    // const numericValue = newValue.replace(/[^0-9]/g, "");
    const numericValue = newValue.replace(/\D/g, "");
    setOtp(numericValue);
    if (!newValue.trim()) {
      setOtpError("Please enter your OTP");
    } else if (!isNaN(numericValue) && numericValue.length === 4) {
      setOtpError("");
      setActive(true);
      setIsDisable(false);
    } else {
      setActive(false);
    }
  };
  const handleSubmit = async (e) => {
    // setIsDisable(true);
    e.preventDefault();
    if (!otp) {
      setOtpError("Please enter your OTP");
    } else if (!isNaN(otp) && otp.length !== 4) {
      setOtpError("Otp must be a 6-digit number");
    } else if (!isNaN(otp) && otp.length === 4) {
      setOtpError("");
    }
    if (!otpError) {
      setIsDisable(true);
      const urlencoded = new URLSearchParams();
      urlencoded.append("masterField", verifyEmail);
      urlencoded.append("otp", +otp);
      // dispatch(PageIndex.adminVerifyOtp(urlencoded)).then((res) => {
      //   if (res?.payload?.status == 200) {
      //     const id = res?.payload?.data?._id;
      //     setTimeout(() => {
      //       navigate("/admin/reset-password", { state: id });
      //     }, 1000);
      //   } else {
      //     setTimeout(() => setIsDisable(false), 3000);
      //   }
      // });
      await DataService.post(Api.User.Auth.SEND_OTP, urlencoded)
        .then((res) => {
          console.log(res?.data, 744);
          toast.success(res?.data?.message);
          // handleOpenResetUser();
          // handleResetPassword();
          // handleResetPassword()
          // handleOpenResetUser()
          handleResetPassword();
          setTimeout(() => {
            setIsDisable(false);
          }, 2000);
          setOtp("");
        })
        .catch((errors) => {
          console.log(errors, 102);
          toast.error(errors?.response?.data?.message);
          // setIsDisable(false);
          // setTimeout(() => {
          //   setIsDisable(true);
          //   setOtp("");
          // }, 2000);
          setOtp("");
          // setTimeout(() => setIsDisable(false));
        });
    }
  };
  // const handleFormSubmit = async (values) => {
  //   // handleOpenResetUser();
  //   console.log(values, 38);
  //   setLoading(true);
  //   const data = {
  //     email: email,
  //     otp: Number(values.Otp),
  //   };
  //   await DataService.post(Api.User.Auth.SEND_OTP, data)
  //     .then((res) => {
  //       console.log(res?.data, 744);
  //       toast.success(res?.data?.message);
  //       // if (res?.data?.status == 200) {
  //       handleOpenResetUser();
  //       setLoading(false);
  //       // }
  //       values.Otp = "";
  //       formikRef.current.resetForm();
  //     })
  //     .catch((errors) => {
  //       console.log(errors, 74);
  //       toast.error(errors?.response?.data?.message);
  //       setLoading(false);
  //       formikRef.current.resetForm();
  //     });
  // };

  function matchIsNumeric(text) {
    const isNumber = typeof text === "number";
    const isString = typeof text === "string";
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  }

  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };

  return (
    <>
      {/* <Index.Modal
        open={openUserOtp}
        onClose={handleCloseUserOtp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="login-modal-inner-main auth-modal-inner modal-common-style"
        > */}
      <Index.Box className="cus-scrollbar modal-hgt-scroll">
        <Index.Box className="modal-header">
          <Index.Typography className="auth-modal-title">OTP</Index.Typography>
          <img
            src={PagesIndex.Svg.closeicon}
            className="modal-close-icon"
            onClick={handleClose}
          />
        </Index.Box>
        {/* <Formik
          innerRef={formikRef}
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => ( */}
        <form onSubmit={handleSubmit}>
          <Index.Box className="modal-body">
            <Index.Box className="comman-form-group comman-otp-details">
              {/*my css  input-box-auth otp-input-box */}
              {/* <Index.MuiOtpInput
                    length={4}
                    className="comman-form-control otp-input"
                    name="Otp"
                    onBlur={handleBlur}
                    autoFocus
                    validateChar={validateChar}
                    type="number"
                    value={values.Otp}
                    onChange={(value) => {
                      const numericValue = value.replace(/\D/g, "");
                      setFieldValue("Otp", numericValue);
                    }}
                    helperText={touched.Otp && errors.Otp}
                    error={Boolean(errors.Otp && touched.Otp)}
                  /> */}
              <Index.Box className="comman-form-control otp-input">
                <Index.OTPInput
                  onChange={handleChange}
                  numInputs={4}
                  value={otp}
                  name="otp"
                  shouldAutoFocus
                  inputType="tel"
                  renderInput={(props) => (
                    <input {...props} inputmode="numeric" pattern="[0-9]*" />
                  )}
                  // renderSeparator={<span>-</span>}
                  // className="otp-input"
                />
              </Index.Box>
              {/* {touched.Otp && errors.Otp && (
                    <Index.FormHelperText error>
                      {errors.Otp}
                    </Index.FormHelperText>
                  )} */}
              {otpError !== " " && <p className="error-text">{otpError}</p>}
            </Index.Box>
          </Index.Box>
          <Index.Box className="modal-footer">
            <Index.Box className="forgot-btn-footer">
              <Index.Box className="modal-auth-btn-main">
                <PagesIndex.OrangeFillButton
                  // btnLabel="Submit"
                  className="orange-fill-btn auth-modal-btn"
                  // type="submit"
                  // disabled={loading}
                  btnLabel="Submit"
                  type="submit"
                  disabled={isDisable || !active || otpError}
                  // startIcon={
                  //   isDisable && (
                  //     <Index.CircularProgress size={15} color="inherit" />
                  //   )
                  // }
                  // disabled={loading}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* <PagesIndex.ResetPassword
            openResetUser={openResetUser}
            email={email}
            handleCloseResetUser={handleCloseResetUser}
            handleCloseForgotUser={handleCloseForgotUser}
            handleCloseUserOtp={handleCloseUserOtp}
            openLoginModal={openLoginModal}
          />  */}
        </form>
        {/* )}
        </Formik> */}
      </Index.Box>
      {/* </Index.Box> */}
      {/* </Index.Modal> */}
    </>
  );
}
