import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Jpg from "../assets/Jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import BannerImage from "../component/common/bannerImage/BannerImage";
import WhoWeAre from "../component/user/pages/about/whoWeAre/WhoWeAre";
import NumbersTalk from "../component/user/pages/about/numbersTalk/NumbersTalk";
import TheTeam from "../component/user/pages/about/theTeam/TheTeam";
import JoinTeam from "../component/user/pages/about/joinTeam/JoinTeam";
import Herosection from "../component/user/pages/home/herosection/Herosection";
import Aboutsection from "../component/user/pages/home/aboutsection/Aboutsection";
import JoinUsSection from "../component/user/pages/home/joinussection/JoinUsSection";
import StatSection from "../component/user/pages/home/statsection/StatSection";
import Ourkeyfeaturesection from "../component/user/pages/home/ourkeyfeaturesection/Ourkeyfeaturesection";
import Userinterface from "../component/user/pages/home/userinterface/Userinterface";
import Applicationwork from "../component/user/pages/home/applicationwork/Applicationwork";
import Categoriessection from "../component/user/pages/home/categoriessection/Categoriessection";
import Blogssection from "../component/user/pages/home/blogssection/Blogssection";
import Testimonialsection from "../component/user/pages/home/testimonialsection/Testimonialsection";
import Helpsection from "../component/user/pages/home/helpsection/Helpsection";
import FaqMain from "../component/user/pages/faq/FaqMain";
import CheckBoxDropdown from "../component/common/dropdown/CheckBoxDropdown";
import ProductCard from "../component/common/productCard/ProductCard";
import OrangeFillButton from "../component/common/buttons/OrangeFillButton";
import GrayFillButton from "../component/common/buttons/GrayFillButton";
import DropDown from "../component/common/dropdown/DropDown";
import BlueFillButton from "../component/common/buttons/BlueFillButton";
import BlueborderButton from "../component/common/buttons/BlueborderButton";
import YouMayLike from "../component/common/youmaylike/YouMayLike";
import Breadcrumb from "../component/common/breadcrumb/Breadcrumb";
import RecentBlogs from "../component/user/pages/blogs/recentBlogs/RecentBlogs";
import LatestPosts from "../component/user/pages/blogs/latestPosts/LatestPosts";
import LatestPostsAll from "../component/user/pages/blogs/latestPostsAll/LatestPostsAll";
import MyAccountContent from "../component/user/pages/myaccount/myaccountcontent/MyAccountContent";
import VerifyAccountModal from "../component/user/pages/myaccount/verifyaccountmodal/VerifyAccountModal";
import BusinessDetails from "../component/user/pages/myaccount/businessdetails/BusinessDetails";
import MyAddress from "../component/user/pages/myaccount/myaddress/MyAddress";
import Settings from "../component/user/pages/myaccount/settings/Settings";
import AnalyticsDashboard from "../component/user/pages/dashboard/dashboardanalytics/AnalyticsDashboard";
import MyQuotations from "../component/user/pages/dashboard/myQuotations/MyQuotations";
import RfqSent from "../component/user/pages/dashboard/rfqSent/RfqSent";
import QuotationRecieved from "../component/user/pages/dashboard/quotationRecieved/QuotationRecieved";
import MyOrder from "../component/user/pages/dashboard/myorder/MyOrder";
import OrangeOutlineButton from "../component/common/buttons/OrangeOutlineButton";
import TrackOrder from "../component/user/pages/dashboard/trackorder/TrackOrder";
import CancelRequest from "../component/user/pages/dashboard/cancelrequest/CancelRequest";
import Expried from "../component/user/pages/dashboard/expried/Expried";
import AdminHeader from "../component/admin/defaultLayout/AdminHeader";
import AdminSidebar from "../component/admin/defaultLayout/AdminSidebar";
import AuthBackground from "./admin/auth/AuthBackground";
import PaidLable from "../component/common/lables/PaidLable";
import FailedLable from "../component/common/lables/FailedLable";
import PendingLable from "../component/common/lables/PendingLable";
import Ra from "../component/user/pages/dashboard/ra/Ra";
import Sidebar from "../component/user/pages/dashboard/sidebar/Sidebar";
import AnalyticIcons from "../component/icons/AnalyticIcons";
import MyOrderIcons from "../component/icons/MyOrderIcons";
import MailIcons from "../component/icons/MailIcons";
import Rating from "../component/common/rating/Rating";
import Login from "../component/user/auth/Login";
import Register from "../component/user/auth/Register";
import DataService, { doPost ,doGet} from "../config/DataService";
import { Api } from "../config/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  adminLogin,
  adminForgotPassword,
  adminVerifyOtp,
  adminResetPassword,
} from "../redux/features/slices/admin/AdminService";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import {
  adminLoginValidationSchema,
  adminResetPasswordValidationSchema,
  adminVerifyOtpValidationSchema,
} from "../validation/validation";
import { ToastContainer, toast } from "react-toastify";
import WorkIcon from "../component/icons/WorkIcon";
import LocationIcon from "../component/icons/LocationIcon";
import SettingIcon from "../component/icons/SettingIcon";
import NotificationIcon from "../component/icons/NotificationIcon";
import Notification from "../component/user/pages/myaccount/notification/Notification";
import ContactUsModal from "../component/common/contactusmodal/ContactUsModal";
import CareerModal from "../component/common/careerModal/CareerModal";
import StarIcon from "../component/icons/StarIcon";
import PlusIcon from "../component/icons/PlusIcon";
import SellerSidebar from "../component/seller/pages/sellerDashboard/sellerSidebar/SellerSidebar";
import SellerAnalytics from "../component/seller/pages/sellerDashboard/sellerAnalytics/SellerAnalytics";
import SellerQuotations from "../component/seller/pages/sellerDashboard/sellerQuotations/SellerQuotations";
import CatalogueCard from "../component/common/catalogueCard/CatalogueCard";
import SellerQuoteBeShared from "../component/seller/pages/sellerDashboard/sellerQuoteBeShared/SellerQuoteBeShared";
import SellerQuotesShared from "../component/seller/pages/sellerDashboard/sellerQuotesShared/SellerQuotesShared";
import SellerCompletedOrder from "../component/seller/pages/sellerDashboard/sellerCompletedOrder/SellerCompletedOrder";
import SellerConfirmedOrder from "../component/seller/pages/sellerDashboard/sellerConfirmedOrder/SellerConfirmedOrder";
import SellerRa from "../component/seller/pages/sellerDashboard/sellerRa/SellerRa";
import SellerRejected from "../component/seller/pages/sellerDashboard/sellerRejected/SellerRejected";
import SellerLost from "../component/seller/pages/sellerDashboard/sellerLost/SellerLost";
import SellerMyAccountContent from "../component/seller/pages/sellerMyAccount/sellerMyAccountContent/SellerMyAccountContent";
import SellerBusinessDetails from "../component/seller/pages/sellerMyAccount/sellerBusinessDetails/SellerBusinessDetails";
import SellerMyAddress from "../component/seller/pages/sellerMyAccount/sellerMyAddress/SellerMyAddress";
import SellerSettings from "../component/seller/pages/sellerMyAccount/SellerSettings/SellerSettings";
import SellerNotification from "../component/seller/pages/sellerMyAccount/sellerNotification/SellerNotification";
import AddNewBuyer from "../component/common/buyerModal/AddNewBuyer";
import AddNewSeller from "../component/common/sellerModal/AddNewSeller";
import EditBuyer from "../component/common/buyerModal/EditBuyer";
import EditSeller from "../component/common/sellerModal/EditSeller";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewBuyer from "../component/common/buyerModal/ViewBuyer";
import ViewSeller from "../component/common/sellerModal/ViewSeller";
import AddProduct from "./admin/pages/productManagement/AddProduct";
import EditProduct from "./admin/pages/productManagement/EditProduct";
import ViewProduct from "./admin/pages/productManagement/ViewProduct";
import EditOrder from "./admin/pages/orderManagement/EditOrder";
import ViewOrder from "./admin/pages/orderManagement/ViewOrder";
import AddPayment from "./admin/pages/paymentManagement/AddPayment";
import ViewPayment from "./admin/pages/paymentManagement/ViewPayment";
import Loader from "../component/common/loader/Loader";

export default {
  Svg,
  Png,
  Jpg,
  Swiper,
  SwiperSlide,
  AuthBackground,
  Navigation,
  Pagination,
  BannerImage,
  WhoWeAre,
  NumbersTalk,
  TheTeam,
  JoinTeam,
  Herosection,
  Aboutsection,
  JoinUsSection,
  StatSection,
  Ourkeyfeaturesection,
  Userinterface,
  Applicationwork,
  Categoriessection,
  Blogssection,
  Testimonialsection,
  Helpsection,
  FaqMain,
  CheckBoxDropdown,
  ProductCard,
  OrangeFillButton,
  GrayFillButton,
  DropDown,
  BlueFillButton,
  YouMayLike,
  Breadcrumb,
  RecentBlogs,
  LatestPosts,
  LatestPostsAll,
  AnalyticsDashboard,
  MyQuotations,
  RfqSent,
  QuotationRecieved,
  MyOrder,
  OrangeOutlineButton,
  TrackOrder,
  MyAccountContent,
  VerifyAccountModal,
  BusinessDetails,
  MyAddress,
  Settings,
  CancelRequest,
  Expried,
  // admin
  AdminHeader,
  AdminSidebar,
  PaidLable,
  PendingLable,
  FailedLable,
  doPost,
  Api,
  useDispatch,
  useSelector,
  adminLogin,
  Formik,
  adminLoginValidationSchema,
  useNavigate,
  Form,
  Ra,
  Sidebar,
  AnalyticIcons,
  MyOrderIcons,
  MailIcons,
  Rating,
  Login,
  Register,
  adminForgotPassword,
  useLocation,
  adminVerifyOtp,
  adminResetPassword,
  ToastContainer,
  adminResetPasswordValidationSchema,
  adminVerifyOtpValidationSchema,
  toast,
  WorkIcon,
  LocationIcon,
  SettingIcon,
  NotificationIcon,
  Notification,
  DataService,
  Navigate,
  ContactUsModal,
  CareerModal,
  StarIcon,
  PlusIcon,
  SellerAnalytics,
  SellerSidebar,
  SellerQuotations,
  CatalogueCard,
  SellerQuoteBeShared,
  SellerQuotesShared,
  SellerCompletedOrder,
  SellerConfirmedOrder,
  SellerRa,
  SellerRejected,
  SellerLost,
  SellerMyAccountContent,
  SellerBusinessDetails,
  SellerMyAddress,
  SellerSettings,
  SellerNotification,
  AddNewBuyer,
  AddNewSeller,
  EditBuyer,
  EditSeller,
  ClearIcon,
  CheckIcon,
  VisibilityIcon,
  ViewBuyer,
  ViewSeller,
  BlueborderButton,
  AddProduct,
  EditProduct,
  ViewProduct,
  EditOrder,
  ViewOrder,
  AddPayment,
  ViewPayment,
  Loader,
  doGet
};
