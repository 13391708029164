import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

export default function Otp() {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const location = PagesIndex.useLocation();
  const [otp, setOtp] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [active, setActive] = useState(false);

  const adminId = location?.state;
  const handleChange = (newValue) => {
    const numericValue = newValue.replace(/[^0-9]/g, "");
    setOtp(numericValue);
    if (!newValue) {
      setOtpError("Please enter your OTP");
    } else if (!isNaN(numericValue) && numericValue.length === 4) {
      setOtpError("");
      setActive(true);
      // Enable the button when 4 digits are entered
    } else {
      // setOtpError("OTP must be a 4-digit number");
      setActive(false);
      // Disable the button if less than 4 digits are entered
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!otp) {
      setOtpError("Please enter your OTP");
    } else if (!isNaN(otp) && otp.length !== 4) {
      setOtpError("Otp must be a 6-digit number");
    } else if (!isNaN(otp) && otp.length === 4) {
      setOtpError("");
    }
    if (!otpError) {
      setIsDisable(true);
      const urlencoded = new URLSearchParams();
      urlencoded.append("id", adminId);
      urlencoded.append("otp", +otp);
      dispatch(PagesIndex.adminVerifyOtp(urlencoded)).then((res) => {
        if (res?.payload?.status == 200) {
          const id = res?.payload?.data?.id;
          setTimeout(() => {
            navigate("/admin/reset-password", { state: id });
          }, 1000);
          setOtp()
        } else {
          setTimeout(() => setIsDisable(false), 3000);
          setOtp()
        }
      });
    }
  };
  function matchIsNumeric(text) {
    const isNumber = typeof text === "number";
    const isString = typeof text === "string";
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  }

  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };

  return (
    <Index.Box className="admin-main">
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <PagesIndex.AuthBackground />
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Otp
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  Please enter Otp
                </Index.Typography>
                <form onSubmit={handleSubmit}>
                  <Index.Box className="otp-flex-main">
                    <Index.Box className="input-box otp-input-box">
                      <Index.Box className="form-group">
                        <Index.MuiOtpInput
                          value={otp}
                          onChange={handleChange}
                          validateChar={validateChar}
                          autoFocus
                          length={4}
                          className="otp-input"
                          name="otp"
                        />
                        {otpError !== " " && (
                          <p className="error-text">{otpError}</p>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="btn-main-primary login-btn-main">
                    <PagesIndex.BlueFillButton
             className={isDisable || !active || otpError? "view-order-btn blue-fill-btn otp-btn disabled":"view-order-btn blue-fill-btn"}

                      btnLabel="Verify OTP"
                      type="submit"
                          disabled={isDisable || !active || otpError}
                          startIcon={
                            isDisable && (
                              <Index.CircularProgress
                                size={15}
                                color="inherit"
                              />
                            )
                          }
                    />
                  </Index.Box>
                </form>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
