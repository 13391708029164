import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Index from "../../../Index";
import PagesIndex from "../../../../component/PagesIndex";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate, useLocation } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AddBlog() {
  const navigate = useNavigate();
  const location = useLocation();
  const editData = location?.state?.row;
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [allTags, setAllTags] = useState([]);

  if (editData) {
    let categoryId = editData
      ? editData?.categoryId?.map((item) => item?._id)
      : [];
    if (categoryId[0]) {
      editData.categoryId = categoryId;
    }
  }
  if (editData) {
    let tagId = editData ? editData?.tagId?.map((item) => item?._id) : [];
    if (tagId[0]) {
      editData.tagId = tagId;
    }
  }
  if (editData) {
    let subCategoryId = editData
      ? editData?.subCategoryId?.map((item) => item?._id)
      : [];
    if (subCategoryId[0]) {
      editData.subCategoryId = subCategoryId;
    }
  }

  const initialValues = {
    title: editData ? editData.title : "",
    description: editData ? editData.description : "",
    blogImage: editData ? editData?.blogImage : "",
    category: editData ? editData?.categoryId : [],
    tagId: editData ? editData?.tagId : [],
    subCategory: editData ? editData?.subCategoryId : [],
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .matches(/^[a-zA-Z\s-, ]+$/, "Accept only alphabetic and white space")
      .required("Please enter your title"),
    description: Yup.string()
      .required("Please enter description")
      .test("maxWords", "Description must be at most 100 words", (value) => {
        if (value) {
          const words = value.split(/\s+/).filter((word) => word.length > 0);
          return words.length <= 100;
        }
        return true;
      }),
    // blogImage: Yup.mixed()
    //   .nullable()
    //   .required("Please select image")
    //   .test(
    //     "FILE_FORMAT",
    //     "allow only jpg,jpeg,png,pdf file",
    //     (value) =>
    //       !value ||
    //       (["jpg", "jpeg", "png", "pdf"].includes(
    //         value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
    //       ) &&
    //         [
    //           "image/jpg",
    //           "image/jpeg",
    //           "image/png",
    //           "application/pdf",
    //         ].includes(value?.type))
    //   )
    //   .test(
    //     "fileSize",
    //     "File size too large, max file size is 2.048 Mb",
    //     (file) => {
    //       if (file) {
    //         return file.size <= 2048000;
    //       } else {
    //         return true;
    //       }
    //     }
    //   ),
    blogImage: Yup.mixed()
      .required("Please select image")
      .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
        console.log(value, 302);
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["jpeg", "jpg", "png"].includes(file); //edit
        } else {
          return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type); //add
        }
      }),

    category: Yup.array()
      .min(1, "Please select at least one category")
      .required("Please select at least one category"),

    tagId: Yup.array()
      .min(1, "Please select at least one tag")
      .required("Please select at least one tag"),

    subCategory: Yup.array()
      .min(1, "Please select at least one sub category")
      .required("Please select at least one sub category"),
  });

  const getAllTags = async () => {
    await DataService.get(Api.Admin.GET_ALL_TAGS)
      .then((res) => {
        setAllTags(res?.data?.data);
      })
      .catch((error) => {});
  };

  const getAllCategory = async () => {
    await DataService.get(Api.Admin.GET_ALL_CATEGORY)
      .then((res) => {
        setAllCategory(res?.data?.data);
      })
      .catch((error) => {});
  };

  const getAllSubcategory = async () => {
    await DataService.get(Api.Admin.GET_ALL_SUB_CATEGORY)
      .then((res) => {
        setAllSubCategory(res?.data?.data);
      })
      .catch((error) => {
        console.log(error, 64);
      });
  };
  // const getSingleData = async () => {
  //   await DataService.get(Api.Admin.GET_SINGLE_DATA)
  //     .then((res) => {
  //       console.log(res, 181);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const handleFormSubmit = async (values) => {
    console.log(values, "values192");
    const data = new FormData();
    data?.append("title", values.title);
    data?.append("description", values.description);
    data?.append("blogImage", image);
    values?.category.forEach((categoryId) =>
      data.append("categoryId", categoryId)
    );
    values?.tagId.forEach((tagId) => data.append("tagId", tagId));

    values?.subCategory.forEach((subCategoryId) =>
      data.append("subCategoryId", subCategoryId)
    );
    // data?.append("subCategoryId", values.subCategory);
    if (editData) {
      data.append("id", editData?._id);
    }
    if (editData) {
      await DataService.post(Api.Admin.UPDATE_BLOG, data)
        .then((res) => {
          toast.success(res?.data?.message);
          navigate("/admin/blogs-list");
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    } else {
      await DataService.post(Api.Admin.ADD_BLOG, data)
        .then((res) => {
          console.log(res, 222);
          toast.success(res?.data?.message);
          navigate("/admin/blogs-list");
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    }
  };
  useEffect(() => {
    getAllSubcategory();
    getAllCategory();
    getAllTags();
    // getSingleData();
  }, []);

  const backBtn = () => {
    navigate("/admin/blogs-list");
  };

  // useEffect(() => {
  //   if (editData) {
  //     setTimeout(() => {
  //       setIsLoading(false);
  //     }, [2000]);
  //   }
  // }, []);
  // if (isLoading) {
  //   return <Index.CircularProgress />;
  // } else {

  return (
    <>
      <Index.Box className="flex-all admin-title-box">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            className="admin-sub-title admin-buyer-title"
            component="h3"
            variant="h3"
          >
            {editData ? <>Edit Blog</> : <>Add Blog</>}
          </Index.Typography>
        </Index.Box>
        <Index.Box className="view-btn-main border-btn-main">
          <PagesIndex.BlueborderButton
            className="cancel-btn blue-border-btn"
            btnLabel="Back"
            onClick={backBtn}
          />
        </Index.Box>
      </Index.Box>

      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Index.Box className="common-grid-main">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 5 }}
                  className="login-row"
                >
                  <Index.Box
                    gridColumn={{
                      xxs: "span 12",
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Title
                        <span className="astric-sign">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="auth-input-main">
                        <Index.TextField
                          className="comman-form-control"
                          placeholder=""
                          type="text"
                          name="title"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.title}
                        />
                      </Index.Box>
                      {errors.title && touched.title && (
                        <p className="error-text">{errors.title}</p>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xxs: "span 12",
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Description
                        <span className="astric-sign">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="auth-input-main">
                        <CKEditor
                          editor={ClassicEditor}
                          name="description"
                          data={values.description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            const strippedData = data.replace(
                              /<\/?[^>]+(>|$)/g,
                              ""
                            );
                            const words = strippedData.trim().split(/\s+/);
                            const limitedContent = words
                              .slice(0, 100)
                              .join(" ");

                            handleChange({
                              target: {
                                name: "description",
                                value: data,
                              },
                            });
                          }}
                        />
                      </Index.Box>
                      {errors.description && touched.description && (
                        <p className="error-text">{errors.description}</p>
                      )}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xxs: "span 12",
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Select Categories
                        <span className="astric-sign">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="auth-input-main">
                        <Index.Select
                          className="comman-form-control"
                          name="category"
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          multiple
                          value={values.category}
                          input={<OutlinedInput />}
                          MenuProps={MenuProps}
                          onChange={(e) =>
                            setFieldValue("category", e.target.value)
                          }
                        >
                          {allCategory?.map((item, index) => (
                            <Index.MenuItem value={item?._id} key={item?._id}>
                              {item?.title}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>
                      {/* <Index.Box className="auth-input-main">
                        <PagesIndex.CheckBoxDropdown />
                      </Index.Box> */}
                      {errors.category && touched.category && (
                        <p className="error-text">{errors.category}</p>
                      )}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xxs: "span 12",
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Select Sub Categories
                        <span className="astric-sign">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="auth-input-main">
                        <Index.Select
                          className="comman-form-control"
                          name="subCategory"
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          multiple
                          value={values.subCategory}
                          input={<OutlinedInput />}
                          MenuProps={MenuProps}
                          onChange={(e) =>
                            setFieldValue("subCategory", e.target.value)
                          }
                        >
                          {allSubCategory?.map((item, index) => (
                            <Index.MenuItem value={item?._id} key={item?._id}>
                              {item?.title}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>
                      {/* <Index.Box className="auth-input-main">
                        <PagesIndex.CheckBoxDropdown />
                      </Index.Box> */}
                      {errors.subCategory && touched.subCategory && (
                        <p className="error-text">{errors.subCategory}</p>
                      )}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xxs: "span 12",
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="comman-form-group">
                      <Index.FormHelperText className="comman-form-label">
                        Select Tags
                        <span className="astric-sign">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="auth-input-main">
                        <Index.Select
                          className="comman-form-control"
                          name="tagId"
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          multiple
                          value={values.tagId}
                          input={<OutlinedInput />}
                          MenuProps={MenuProps}
                          onChange={(e) =>
                            setFieldValue("tagId", e.target.value)
                          }
                        >
                          {allTags?.map((item, index) => (
                            <Index.MenuItem value={item?._id} key={item?._id}>
                              {item?.title}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>
                      {/* <Index.Box className="auth-input-main">
                        <PagesIndex.CheckBoxDropdown />
                      </Index.Box> */}
                      {errors.tagId && touched.tagId && (
                        <p className="error-text">{errors.tagId}</p>
                      )}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xxs: "span 12",
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="comman-form-group ">
                      <Index.FormHelperText className="comman-form-label">
                        Upload your image
                        <span className="astric-sign">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="common-edit-image-box add-product">
                        {values?.image ? (
                          <Index.Box className="common-edit-image">
                            <img
                              src={
                                typeof values.image === "string"
                                  ? values.image
                                  : URL.createObjectURL(values.image)
                              }
                              className="product-img"
                              alt="product"
                            />
                            <img
                              src={PagesIndex.Svg.closeicon}
                              className="product-img-close"
                              onClick={() => setFieldValue("image", "")}
                            />
                          </Index.Box>
                        ) : (
                          <Index.Box className="upload-img-file-modal">
                            <Index.Button
                              component="label"
                              variant="contained"
                              className="upload-label-details"
                            >
                              <Index.Box className="upload-main-content">
                                <img
                                  src={PagesIndex.Png.cloud}
                                  className="upload-cloud"
                                  alt="cloud"
                                />
                              </Index.Box>
                              <Index.Typography className="browse-cloud-text">
                                Browse Files to upload
                              </Index.Typography>
                              <Index.Typography className="browse-cloud-accepted">
                                (accepted JPG & PNG only)
                              </Index.Typography>
                              <VisuallyHiddenInput
                                type="file"
                                className="comman-form-control"
                                name="image"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue("image", e.target.files[0]);
                                }}
                              />
                            </Index.Button>
                          </Index.Box>
                        )}
                      </Index.Box>
                      {errors.image && touched.image && (
                        <p className="error-text">{errors.image}</p>
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-footer-btn-box">
              <PagesIndex.BlueborderButton
                className="cancel-btn blue-border-btn"
                btnLabel="Cancel"
                onClick={backBtn}
              />
              <PagesIndex.BlueFillButton
                btnLabel={
                  !isLoading ? (
                    "Submit"
                  ) : (
                    <>
                      <Index.CircularProgress
                        sx={{ color: "white", mr: 1 }}
                        size={24}
                      />
                    </>
                  )
                }
                className="blue-fill-btn"
                type="submit"
                disabled={isLoading}
                disableRipple
              />
            </Index.Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
