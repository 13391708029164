import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

// for custom checkbox design

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 0,
  border: "1px solid #114A65",
  width: 16,
  height: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export default function ResetPassWord() {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const location = PagesIndex.useLocation();
  const adminId = location?.state;
  // for password eye hide and show

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickshowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickshowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  let initialValues = {
    Password: "",
    confirmPassword: "",
    isSubmit: false,
  };
  const handleSubmit = (values, { setFieldValue ,resetForm }) => {
    setFieldValue("isSubmit", true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", adminId);
    urlencoded.append("newPassword", values.Password);
    urlencoded.append("confirmPassword", values.confirmPassword);
    dispatch(PagesIndex.adminResetPassword(urlencoded)).then((res) => {
      if (res?.payload?.status == 200) {
        setTimeout(() => {
          navigate("/admin");
          
        }, 1000);
      } else {
        setTimeout(() => setFieldValue("isSubmit", false), 3000);
      }
      resetForm();
    });
   
  };
  return (
    <Index.Box className="admin-main">
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <PagesIndex.AuthBackground />
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Reset Password
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  {/* Please enter your credentials to Forgot Password ! */}
                </Index.Typography>
                <PagesIndex.Formik
                  initialValues={initialValues}
                  validationSchema={
                    PagesIndex.adminResetPasswordValidationSchema
                  }
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                  }) => (
                    <PagesIndex.Form>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.OutlinedInput
                            className="form-control-eye"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            type={showNewPassword ? "text" : "password"}
                            name="Password"
                            value={values?.Password}
                            placeholder="Please enter your new password"
                            onChange={(e) => {
                              const newValue = e.target.value.replace(
                                /\s/g,
                                ""
                              );
                              setFieldValue("Password", newValue);
                            }}
                            onBlur={handleBlur}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickshowNewPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showNewPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                          {errors.Password && touched.Password && (
                            <p className="error-text">{errors.Password}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Confirm Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.OutlinedInput
                            className="form-control-eye"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            placeholder="Please enter your confirm password "
                            value={values?.confirmPassword}
                            onChange={(e) => {
                              const newValue = e.target.value.replace(
                                /\s/g,
                                ""
                              );
                              setFieldValue("confirmPassword", newValue);
                            }}
                            onBlur={handleBlur}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickshowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                          {errors.confirmPassword &&
                            touched.confirmPassword && (
                              <p className="error-text">
                                {errors.confirmPassword}
                              </p>
                            )}
                        </Index.Box>
                      </Index.Box>
                      {/* <Index.Box className="flex-all forgot-row">
                        <Index.Box className="checkbox-main">
                          <BpCheckbox />
                          <Index.Typography className="checkbox-lable">
                            Remember Me
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box> */}
                      {/* <Index.Box className="btn-main-primary login-btn-main"> */}
                      <Index.Box className="view-btn-main border-btn-main login-btn-main">
                        <PagesIndex.BlueFillButton
                          className={
                            values?.isSubmit
                              ? "view-order-btn blue-fill-btn login-btn disabled-btn disabled"
                              : "view-order-btn blue-fill-btn login-btn"
                          }
                          btnLabel="Submit"
                          type="submit"
                          disabled={values?.isSubmit}
                          startIcon={
                            values?.isSubmit && (
                              <Index.CircularProgress
                                size={15}
                                color="inherit"
                              />
                            )
                          }
                        />
                        {/* </Index.Box> */}
                      </Index.Box>
                    </PagesIndex.Form>
                  )}
                </PagesIndex.Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
