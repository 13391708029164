import React from 'react'
import './myCart.css'
import './myCart.responsive.css'
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

function EmptyCart() {

  return (
    <Index.Box className="my-cart-bg-main empty-cart">
      <Index.Box className="mycart-breadcrumb">
        <PagesIndex.Breadcrumb />
      </Index.Box>
      <Index.Box className="cus-container">
        <Index.Box className="mycart-details-content">
          <Index.Box className="mycart-row-details">
            <Index.Box className="mycart-col1">
              <Index.Box className="item-show-content">
                <img src={PagesIndex.Svg.emptyCart} alt='empty cart' className='empty-cart-img' />
                <Index.Typography className='empty-cart-title'>
                  Your cart is empty
                </Index.Typography>
                <Index.Typography className='empty-cart-subtitle'>
                  Proceed with bulk order
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="mycart-col2">
              <Index.Box className="product-bulk-card common-card">
                <Index.Box className="bulk-order-flex">
                  <Index.Typography
                    className="bulk-order-text"
                    component="h3"
                    variant="h3"
                  >
                    Bulk order
                  </Index.Typography>
                  <Index.Link className="download-sample-text">
                    <img
                      src={PagesIndex.Svg.downloadicon}
                      className="download-icon"
                      alt="download sample"
                    />
                    Download sample
                    <img
                      src={PagesIndex.Svg.downinfo}
                      className="down-info-icon"
                    />
                  </Index.Link>
                </Index.Box>
                <Index.Box className="choose-file-main">
                  <Index.TextField
                    className="choose-file-control"
                    type="file"
                    placeholder="Choose file"
                  />
                  <Index.Box className="choose-file-cust-main">
                    <Index.Box className="choose-file-cust-flex">
                      <Index.Box className="choose-file-left-main">
                        <Index.Typography
                          className="choose-file-text"
                          component="p"
                          variant="p"
                        >
                          Choose file
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="choose-file-right-main">
                        <Index.Typography
                          className="choose-browse-text"
                          component="p"
                          variant="p"
                        >
                          Browse file
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="upload-blue-main">
                  <Index.Button className="upload-blue-btn">
                    <img
                      src={PagesIndex.Svg.uploadfileicon}
                      alt="upload file"
                      className="upload-file-icon"
                    />
                    Upload
                  </Index.Button>
                </Index.Box>
                <Index.Typography
                  className="payment-term-text"
                  component="p"
                  variant="p"
                >
                  Payment term
                </Index.Typography>
                <Index.Box className="payment-tem-drop-main">
                  <PagesIndex.DropDown />
                </Index.Box>
                <PagesIndex.OrangeFillButton
                  btnLabel="Send enquiry"
                  className="orange-fill-btn send-inquery-btn"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}

export default EmptyCart