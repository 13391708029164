import logo from "./images/svg/brand.svg";
import facebook from "./images/svg/facbook.svg";
import linkedin from "./images/svg/linkedin.svg";
import twiter from "./images/svg/twitter.svg";
import insta from "./images/svg/insta-icon.svg";
import bluearrowbig from "./images/svg/bluearrowBig.svg";
import bluearrow from "./images/svg/blluearrow.svg";
import roundarrow from "./images/svg/arrow_circle_right.svg";
import whitelongarrow from "./images/svg/white-long-arrow.svg";
import prvwhitethik from "./images/svg/prev-white-thik-arrow.svg";
import nxtwhitethik from "./images/svg/next-white-thik-arrow.svg";
import prvblackarrrow from "./images/svg/prev-black-arrow.svg";
import nxtblackarrrow from "./images/svg/next-black-arrow.svg";
import frwdbluearrrow from "./images/svg/arrow_forward_blue.svg";
import frwdorangearrrow from "./images/svg/arrow_circle_right-orange.svg";
import frwmtorangearrrow from "./images/svg/material-symbols_arrow-forward.svg";
import backmtorangearrrow from "./images/svg/material-symbols_arrow-backward.svg";
import testiprevarrrow from "./images/svg/testimonial-prev-arrow.svg";
import testinextarrrow from "./images/svg/testimonial-arrow-next.svg";
import customer from "./images/svg/icon-customer.svg";
import search from "./images/svg/search.svg";
import greysearch from "./images/svg/greysearch.svg";
import bluesearch from "./images/svg/blue-search.svg";
import bluesetting from "./images/svg/blue-setting.svg";
import bluetick from "./images/svg/blue-tick.svg";
import bluelist from "./images/svg/bluelist.svg";
import appwork from "./images/svg/appwork.svg";
import howto from "./images/svg/how_to_reg.svg";
import exportnote from "./images/svg/export_notes.svg";
import partnerexchange from "./images/svg/partner_exchange.svg";
import route from "./images/svg/fa-solid_route.svg";
import verifyblack from "./images/svg/verified_2.svg";
import mystery from "./images/svg/mystery.svg";
import speaker from "./images/svg/speaker_notes.svg";
import game from "./images/svg/game-icons_pay-money.svg";
import delivery from "./images/svg/solar_delivery-bold.svg";
import burgermenu from "./images/svg/burger-menu.svg";
import dropdownarrow from "./images/svg/arrow_drop_down.svg";
import downloadicon from "./images/svg/download.svg";
import uploadfileicon from "./images/svg/upload_file.svg";
import closeicon from "./images/svg/close.svg";
import arrowBackIosNew from "./images/svg/arrow_back_ios_new.svg";
import keyboardArrowRight from "./images/svg/keyboard_arrow_right.svg";
import playCircle from "./images/svg/play_circle.svg";
import quotations from "./images/svg/quotations.svg";
import myorders from "./images/svg/myorders.svg";
import analytics from "./images/svg/analytics.svg";
import arrowup from "./images/svg/arrow-up.svg";
import moreVert from "./images/svg/more_vert.svg";
import uploadfilemodal from "./images/svg/upload-file.svg";
import filedownload from "./images/svg/filedownload.svg";
import workicon from "./images/svg/work.svg";
import locationicon from "./images/svg/location_on.svg";
import settingsicon from "./images/svg/settings.svg";
import erroricon from "./images/svg/error.svg";
import cloudupload from "./images/svg/cloud_upload.svg";
import descriptionicon from "./images/svg/description.svg";
import deleteicon from "./images/svg/delete.svg";
import uploaddone from "./images/svg/cloud_done.svg";
import logouticon from "./images/svg/logout.svg";
import verifyicon from "./images/svg/verify.svg";
import notification from "./images/svg/notification.svg";
import dashboard from "./images/svg/dashboard.svg";
import userlist from "./images/svg/user-list.svg";
import usercard from "./images/svg/user-card.svg";
import changepassword from "./images/svg/change-password.svg";
import dashicon2 from "./images/svg/dash2.svg";
import dashicon1 from "./images/svg/dash1.svg";
import dashicon3 from "./images/svg/dash3.svg";
import dashicon4 from "./images/svg/dash4.svg";
import invoice from "./images/svg/invoice.svg";
import grentick from "./images/svg/gren-tick.svg";
import editrat from "./images/svg/edit-rat.svg";
import downinfo from "./images/svg/info.svg";
import thumbsUp from "./images/svg/thumbs-up.svg";
import appleStore from "./images/svg/appleStore.svg";
import playStore from "./images/svg/playStore.svg";
import editingIcon from "./images/svg/editing.svg";
import pdfIcon from "./images/svg/pdf.svg";
import replyIcon from "./images/svg/reply.svg";
import emptyCart from "./images/svg/empty_cart.svg";
import pen from "./images/svg/pen.svg";

const Svg = {
  editingIcon,
  howto,
  exportnote,
  changepassword,
  partnerexchange,
  route,
  dashboard,
  verifyblack,
  mystery,
  speaker,
  game,
  delivery,
  logo,
  facebook,
  insta,
  linkedin,
  twiter,
  bluearrowbig,
  bluearrow,
  roundarrow,
  customer,
  search,
  greysearch,
  bluesearch,
  bluesetting,
  bluetick,
  bluelist,
  whitelongarrow,
  prvwhitethik,
  nxtwhitethik,
  prvblackarrrow,
  nxtblackarrrow,
  frwdbluearrrow,
  frwdorangearrrow,
  frwmtorangearrrow,
  backmtorangearrrow,
  appwork,
  burgermenu,
  testiprevarrrow,
  testinextarrrow,
  dropdownarrow,
  downloadicon,
  uploadfileicon,
  closeicon,
  arrowBackIosNew,
  keyboardArrowRight,
  playCircle,
  quotations,
  myorders,
  analytics,
  arrowup,
  moreVert,
  uploadfilemodal,
  moreVert,
  filedownload,
  workicon,
  locationicon,
  settingsicon,
  erroricon,
  cloudupload,
  uploaddone,
  deleteicon,
  descriptionicon,
  logouticon,
  verifyicon,
  notification,
  userlist,
  usercard,
  dashicon1,
  dashicon2,
  dashicon3,
  dashicon4,
  invoice,
  grentick,
  editrat,
  downinfo,
  thumbsUp,
  appleStore,
  playStore,
  pdfIcon,
  replyIcon,
  emptyCart,
  pen,
};

export default Svg;
