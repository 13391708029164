import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./productDetail.css";
import "./productDetail.responsive.css";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { Form, Formik } from "formik";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

function ProductDetailTab(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Index.Box>{children}</Index.Box>}
    </div>
  );
}

ProductDetailTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const dimensions = ["dimensions1", "dimensions2", "dimensions3"];
const payment = ["Advance", "Partial", "Full"];
// const payment = ["AdvancePaymnet", "PartialPayment", "FullPayment"];

export default function ProductDetail() {
  const location = useLocation();
  const row = location?.state?.row;
  console.log(row, "row");
  const [value, setValue] = useState(0);
  const [brandData, setBrandData] = useState([]);
  const [dimensionsData, setDimensionsData] = useState(dimensions);
  const [paymentData, setPaymentData] = useState(payment);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  // EnquirySuccess modal
  const [openEnquirySuccess, setOpenEnquirySuccess] = useState(false);
  const handleOpenEnquirySuccess = () => setOpenEnquirySuccess(true);
  const handleCloseEnquirySuccess = () => setOpenEnquirySuccess(false);
  const [loading, setLoading] = useState(false);

  const removeImageTags = (html) => {
    console.log(html, 31);
    return html?.replace(/<(table|figure|[^>]+)>/g, "");
  };
  const img = row ? row?.image : "-";
  console.log(img, 68);

  const initialValues = {
    brand: row.brand ? row?.brand?.map((item) => item?._id) : [],
    // brand: [],
    dimention: row ? row?.dimention : "",
    // dimention: "",
    quantity: row ? row?.quantity : 0,
    payment: row ? row?.payment : "",
  };

  const getBrands = async () => {
    await DataService.get(Api.User.GET_BRANDS)
      .then((res) => {
        console.log(res, 352);
        setBrandData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // GET_SINGLE_PRODUCT
  // const GetSingleValue = async () => {
  //   // await DataService.get(`${Api.User.GET_SINGLE_PRODUCT}/${row?._id}`)
  //   await DataService.get(`${Api.User.GET_SINGLE_PRODUCT}`, {
  //     params: { id: row },
  //   })
  //     .then((res) => {
  //       console.log(res, 126);
  //     })
  //     .catch((error) => {
  //       console.log(error, 104);
  //       toast.error(error?.response?.data?.message);
  //     });
  // };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    console.log(values, "values100");
    const data = {
      productId: row?._id,
      quantity: values?.quantity,
      paymentTerms: values?.payment,
      dimensions: values?.dimention,
    };
    await DataService.post(Api.User.SEND_BUYER_ENQUIRY, data)
      .then((res) => {
        console.log(res, 102);
        if (res?.data?.status == 201) {
          toast.success(res?.data?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error, 104);
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };
  useEffect(() => {
    getBrands();
    // if (row) {
    //   GetSingleValue();
    // }
  }, [row]);
  return (
    <>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="product-img-modal-main modal-common-style"
        >
          <Index.Box className="cus-scrollbar modal-hgt-scroll">
            <Index.Box className="modal-header">
              <Index.Typography
                className="product-img-modal-title"
                component="h3"
                variant="h3"
              >
                Images
              </Index.Typography>
              <img
                src={PagesIndex.Svg.closeicon}
                className="modal-close-icon"
                onClick={handleClose}
              />
            </Index.Box>
            <Index.Box className="modal-body">
              <Index.Box className="product-image-thumb-box cus-scrollbar">
                {row?.image?.map((row, key) => {
                  return (
                    <img
                      key={key}
                      src={`http://194.233.77.156:3042/images/${row}`}
                      alt="Product"
                      className="product-image-thumb"
                    />
                  );
                })}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <Index.Box className="main-product-detail" component="main">
        <PagesIndex.Breadcrumb />
        <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
          {({
            handleSubmit,
            values,
            handleBlur,
            handleChange,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Index.Box className="cus-container">
                <Index.Box className="product-detail-wrapper">
                  <Index.Box className="product-detail-left">
                    <Index.Box className="product-title-box">
                      <Index.Box className="product-title">
                        Mild Steel Sheet
                      </Index.Box>
                      <Index.Box className="product-content-box">
                        <Index.Box className="product-content">
                          Lorem ipsum dolor sit amet consectetur. Vulputate
                          sodales neque nibh semper nec tellus.
                        </Index.Box>
                        <Index.Box className="product-review-box">
                          <Index.Box className="product-review-star">
                            4.5
                            <PagesIndex.StarIcon />
                          </Index.Box>
                          <Index.Box className="product-review-count">
                            (25 Reviews)
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="product-image-box">
                      <Index.Box className="product-image-thumb-box">
                        {row?.image?.length > 1 ? (
                          <img
                            src={`http://194.233.77.156:3042/images/${img[1]}`}
                            alt="Product"
                            className="product-image-thumb"
                          />
                        ) : (
                          ""
                        )}
                        {row?.image?.length > 2 ? (
                          <img
                            src={`http://194.233.77.156:3042/images/${img[2]}`}
                            alt="Product"
                            className="product-image-thumb"
                          />
                        ) : (
                          ""
                        )}
                        {console.log(row?.image?.length, 217)}
                        {row?.image?.length > 3 ? (
                          <Index.Box
                            className="product-image-more"
                            onClick={handleOpen}
                          >
                            <PagesIndex.PlusIcon />
                            More
                          </Index.Box>
                        ) : (
                          ""
                        )}
                      </Index.Box>
                      <Index.Box className="product-image-main">
                        <img
                          // src={PagesIndex.Jpg.productImg1}
                          src={`http://194.233.77.156:3042/images/${img[0]}`}
                          alt="Product"
                          className="product-image"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="product-detail-right">
                    <Index.Box className="product-select-top">
                      <Index.Box className="product-select-row">
                        <Index.Box className="product-select-label">
                          Brand
                        </Index.Box>
                        <Index.Box className="product-select-drop-main">
                          <PagesIndex.CheckBoxDropdown
                            options={brandData}
                            handleChange={handleChange}
                            value={values.brand}
                            name="brand"
                            multiple
                          />
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="product-select-row">
                        <Index.Box className="product-select-label">
                          Dimensions
                        </Index.Box>
                        <Index.Box className="product-select-drop-main">
                          <PagesIndex.DropDown
                            options={dimensionsData}
                            handleChange={handleChange}
                            value={values.dimention}
                            name="dimention"
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="product-select-row">
                        <Index.Box className="product-select-label">
                          Qty
                        </Index.Box>
                        <Index.Box className="product-select-qty">
                          <Index.Button
                            className="product-select-qty-btn"
                            onClick={() => {
                              if (values.quantity > 0) {
                                setFieldValue(
                                  "quantity",
                                  Number(values.quantity) - 1
                                );
                              }
                            }}
                          >
                            -
                          </Index.Button>
                          <Index.Input
                            type="number"
                            name="quantity"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.quantity}
                          />
                          <Index.Button
                            className="product-select-qty-btn"
                            onClick={() => {
                              setFieldValue(
                                "quantity",
                                Number(values.quantity) + 1
                              );
                            }}
                          >
                            +
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="product-select-row">
                        <Index.Box className="product-select-label">
                          Payment term
                        </Index.Box>
                        <Index.Box className="product-select-drop-main">
                          <PagesIndex.DropDown
                            options={paymentData}
                            handleChange={handleChange}
                            value={values.payment}
                            name="payment"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <PagesIndex.OrangeFillButton
                      btnLabel="Send enquiry"
                      // onClick={handleOpenEnquirySuccess}
                      className="orange-fill-btn send-enquiry-btn"
                      type="submit"
                      disabled={loading}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Form>
          )}
        </Formik>
        <Index.Box className="product-detail-tab-bg">
          <Index.Box className="cus-container">
            <Index.Box className="product-detail-tab-wrapper">
              <Index.Tabs
                variant="scrollable"
                scrollButtons={false}
                value={value}
                onChange={handleChange}
                className="product-detail-tab-box"
                aria-label="product-detail-tab-box"
              >
                <Index.Tab
                  className="product-detail-tab-btn"
                  label="Description"
                  {...a11yProps(0)}
                />
                <Index.Tab
                  className="product-detail-tab-btn"
                  label="Specification"
                  {...a11yProps(1)}
                />
              </Index.Tabs>
              <ProductDetailTab
                value={value}
                index={0}
                className="product-detail-tab-panel"
              >
                {/* Lorem ipsum dolor sit amet consectetur. Amet tincidunt fringilla
                erat varius massa tempus velit hac dolor. Pellentesque praesent
                viverra at quis vitae id. Eros ultrices nunc ipsum sit tincidunt
                nisl egestas nibh. Id enim purus consectetur in condimentum.
                Volutpat in feugiat enim et tortor faucibus pellentesque massa.
                Eget mauris ullamcorper egestas arcu lacus gravida curabitur
                fermentum commodo. Malesuada donec sed tincidunt commodo. Felis
                dictumst adipiscing vitae dis tellus id arcu mi sapien. Urna nam
                at tortor tempus consectetur nulla aliquet eget. Ornare egestas
                quisque et eget nam lorem placerat adipiscing. Auctor commodo
                pulvinar urna id. Nulla in amet malesuada nulla. Integer
                malesuada aliquet phasellus cursus. In scelerisque id eget
                sagittis pellentesque. Tortor sodales fringilla vitae aliquam
                feugiat quisque. Enim at nunc mattis ligula euismod a
                scelerisque. Urna odio nisl id lacinia lorem pulvinar facilisis.
                Tristique facilisis sed pellentesque id tincidunt augue. Sed
                netus nulla eget id. */}
                {row ? removeImageTags(row?.description) : "-"}
              </ProductDetailTab>
              <ProductDetailTab
                value={value}
                index={1}
                className="product-detail-tab-panel"
              >
                {/* Lorem ipsum dolor sit amet consectetur. Amet tincidunt fringilla
                erat varius massa tempus velit hac dolor. Pellentesque praesent
                viverra at quis vitae id. Eros ultrices nunc ipsum sit tincidunt
                nisl egestas nibh. Id enim purus consectetur in condimentum.
                Volutpat in feugiat enim et tortor faucibus pellentesque massa.
                Eget mauris ullamcorper egestas arcu lacus gravida curabitur
                fermentum commodo. */}
                {row ? removeImageTags(row?.specification) : "-"}
              </ProductDetailTab>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <PagesIndex.YouMayLike />
      </Index.Box>
      <Index.Modal
        open={openEnquirySuccess}
        onClose={handleCloseEnquirySuccess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >
        <Index.Box
          sx={style}
          className="goto-veryfication-modal-inner-main modal-common-style"
        >
          <Index.Box className="cus-scrollbar modal-hgt-scroll">
            <Index.Box className="modal-header">
              <img
                src={PagesIndex.Svg.closeicon}
                className="modal-close-icon"
                onClick={handleCloseEnquirySuccess}
              />
            </Index.Box>
            <Index.Box className="modal-logo-main">
              <img src={PagesIndex.Svg.logo} className="modal-logo" />
            </Index.Box>
            <Index.Typography
              className="veryfy-modal-para"
              component="p"
              variant="p"
            >
              Enquiry has been placed!
              <br />
              You can check your Qutations right now
            </Index.Typography>
            <Index.Box className="modal-veryfy-btn-main">
              <PagesIndex.BlueFillButton
                btnLabel="Go to my Quotations"
                className="blue-fill-btn veryfy-modal-btn"
                onClick={() =>
                  navigate("/dashboard", {
                    state: { num: 2 },
                  })
                }
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
