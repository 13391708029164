import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { ListItemText, OutlinedInput } from "@mui/material";
import CheckBoxDropdown from "../../../../component/common/dropdown/CheckBoxDropdown";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { RoofingTwoTone } from "@mui/icons-material";
import Dropdown from "../../../../component/common/dropdown/DropDown";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const payment = ["Advance", "Partial", "Full"];
const dimensions = ["dimensions1", "dimensions2", "dimensions3"];

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AddProduct() {
  const navigate = useNavigate();
  const location = useLocation();
  const row = location?.state?.row;

  const [singleProduct, setSingleProduct] = useState({});

  const [dimensionsData, setDimensionsData] = useState(dimensions);
  const [brandData, setBrandData] = useState([]);
  const [thicknessData, setThicknessData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [gradsData, setGradsData] = useState([]);

  const [applicationData, setApplicationData] = useState([]);
  const [paymentData, setPaymentData] = useState(payment);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [imageCataLogueImage, setImageCataLogueImage] = useState([]);
  const [imageData, setImageData] = useState([]);

  console.log(imageCataLogueImage, 68);
  console.log(imageData, 69);
  const [removeDocumentsPayload, setRemoveDocumentsPayload] = useState([]);

  const image = singleProduct ? singleProduct?.image : "";

  useEffect(() => {
    setImageCataLogueImage(image);
  }, [image]);

  // useEffect(() => {
  //   setImageData(image);
  // }, [image]);

  const initialValues = {
    removedoc: [],
    name: singleProduct ? singleProduct?.name : "",
    description: singleProduct ? singleProduct?.description : "",
    specification: singleProduct ? singleProduct?.specification : "",
    brand: singleProduct.brand
      ? singleProduct?.brand?.map((item) => item?._id)
      : [],
    dimention: singleProduct ? singleProduct?.dimention : "",
    payment: singleProduct ? singleProduct?.payment : "",

    quantity: singleProduct ? singleProduct.quantity : 0,
    thickness: singleProduct.thickness
      ? singleProduct?.thickness?.map((item) => item?._id)
      : [],
    type: singleProduct.type
      ? singleProduct?.type?.map((item) => item?._id)
      : [],
    grad: singleProduct.grad
      ? singleProduct?.grad?.map((item) => item?._id)
      : [],
    application: singleProduct.application
      ? singleProduct?.application?.map((item) => item?._id)
      : [],

    productCategoryId: singleProduct.productCategoryId
      ? singleProduct?.productCategoryId?.map((item) => item?._id)
      : [],

    productSubCategoryId: singleProduct.productSubCategoryId
      ? singleProduct?.productSubCategoryId?.map((item) => item?._id)
      : [],

    productImages: "",

    action: singleProduct ? "edit" : "add",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z\s-, ]+$/, "Accept only alphabetic and white space")
      .required("Please enter product name"),
    description: Yup.string()
      .required("Please enter description")
      .test("maxWords", "Description must be at most 100 words", (value) => {
        if (value) {
          const words = value.split(/\s+/).filter((word) => word.length > 0);
          return words.length <= 100;
        }
        return true;
      }),
    specification: Yup.string()
      .required("Please enter specification")
      .test("maxWords", "Specification must be at most 100 words", (value) => {
        if (value) {
          const words = value.split(/\s+/).filter((word) => word.length > 0);
          return words.length <= 100;
        }
        return true;
      }),

    quantity: Yup.number().required("Please enter quantity"),

    // productImages: Yup.array()
    //   .min(1, "Please select image")
    //   .test("fileType", "Allow only jpg, jpeg, png file", (value) => {
    //     console.log(value,"value147")
    //     if (value=="undefined") return false;
    //     for (const file of value) {
    //       const validExtensions = ["jpg", "jpeg", "png", "gif", "pdf"];
    //       const fileExtension = file.name.split(".").pop().toLowerCase();
    //       if (!validExtensions.includes(fileExtension)) {
    //         return false;
    //       }
    //     }
    //     return true;
    //   }),

    // productImages: Yup.array().when("action", {
    //   is: "add", // Replace 'add' with your actual condition for adding
    //   then: Yup.array()
    //     .min(1, "Please select image")
    //     .test("fileType", "Allow only jpg, jpeg, png file", (value) => {
    //       console.log(value, "value147");
    //       if (!value) return false; // Check if value is undefined
    //       for (const file of value) {
    //         const validExtensions = ["jpg", "jpeg", "png", "gif", "pdf"];
    //         const fileExtension = file.name.split(".").pop().toLowerCase();
    //         if (!validExtensions.includes(fileExtension)) {
    //           return false;
    //         }
    //       }
    //       return true;
    //     }),
    //   otherwise: Yup.array(), // No validation for 'edit'
    // }),

    brand: Yup.array()
      .min(1, "Please select at least one brand")
      .required("Please select at least one brand"),

    thickness: Yup.array()
      .min(1, "Please select at least one thickness")
      .required("Please select at least one thickness"),

    type: Yup.array()
      .min(1, "Please select at least one type")
      .required("Please select at least one type"),

    grad: Yup.array()
      .min(1, "Please select at least one grad")
      .required("Please select at least one grad"),

    application: Yup.array()
      .min(1, "Please select at least one application")
      .required("Please select at least one application"),

    payment: Yup.string().required("Please select payment"),

    dimention: Yup.string().required("Please select dimension"),

    productCategoryId: Yup.array()
      .min(1, "Please select at least one product category")
      .required("Please select at least one product category"),
    productSubCategoryId: Yup.array()
      .min(1, "Please select at least one product sub category")
      .required("Please select at least one product sub category"),
  });

  const backBtn = () => {
    navigate("/admin/product-management");
  };
  const handleFormSubmit = async (values) => {
    console.log(values, 44);
    const data = new FormData();
    data.append("name", values?.name);
    data.append("description", values?.description);
    data.append("specification", values?.specification);
    data.append("quantity", values?.quantity);

    values?.brand.forEach((brand) => data.append("brand", brand));

    data.append("payment", values.payment);
    data.append("dimention", values.dimention);

    values?.thickness.forEach((thickness) =>
      data.append("thickness", thickness)
    );
    values?.type.forEach((type) => data.append("type", type));

    values?.grad.forEach((grad) => data.append("grad", grad));

    values?.application.forEach((application) =>
      data.append("application", application)
    );

    values?.productCategoryId.forEach((productCategoryId) =>
      data.append("productCategoryId", productCategoryId)
    );

    values?.productSubCategoryId.forEach((productSubCategoryId) =>
      data.append("productSubCategoryId", productSubCategoryId)
    );

    imageCataLogueImage?.map((e) => {
      data.append("productImages", e);
    });

    if (removeDocumentsPayload.length > 0) {
      data.append("removedoc", removeDocumentsPayload);
    } else {
      data.append("removedoc", []);
    }

    if (singleProduct?._id) {
      data.append("id", singleProduct?._id);
    }

    setLoading(true);
    await DataService.post(Api.Admin.CREATE_PRODUCT, data)
      .then((res) => {
        console.log(res, 86);

        if (res?.data?.status === 201) {
          setLoading(false);
          toast.success(res?.data?.message);
          navigate("/admin/product-management");
        }
        if (res?.data?.status === 200) {
          setLoading(false);
          toast.success(res?.data?.message);
          navigate("/admin/product-management");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const getSingle = async () => {
    setIsLoading(true);
    await DataService.get(`${Api.Admin.GET_SINGLE_PRODUCT}?id=${row}`)
      .then((res) => {
        console.log(res, 18);
        setSingleProduct(res?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getBrands = async () => {
    await DataService.get(Api.Admin.GET_BRANDS)
      .then((res) => {
        console.log(res, 352);
        setBrandData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGrads = async () => {
    await DataService.get(Api.Admin.GET_GRADS)
      .then((res) => {
        setGradsData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTypes = async () => {
    await DataService.get(Api.Admin.GET_TYPES)
      .then((res) => {
        console.log(res?.data?.data);
        setTypeData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getThickness = async () => {
    await DataService.get(Api.Admin.GET_THICKNESS)
      .then((res) => {
        setThicknessData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getApplication = async () => {
    await DataService.get(Api.Admin.GET_APPLICATION)
      .then((res) => {
        setApplicationData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCategory = async () => {
    await DataService.get(Api.Admin.GET_PRODUCT_CATEGORY)
      .then((res) => {
        setCategoryData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubCategory = async () => {
    await DataService.get(Api.Admin.GET_PRODUCT_SUB_CATEGORY)
      .then((res) => {
        setSubCategoryData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (row) {
      getSingle();
    }
  }, []);

  useEffect(() => {
    getBrands();
    getGrads();
    getTypes();
    getThickness();
    getApplication();
    getCategory();
    getSubCategory();
  }, []);

  const handleImageDelete = (imageName, index) => {
    console.log(imageName, 446);
    setRemoveDocumentsPayload((state) => {
      const updatedImages = [...imageCataLogueImage];
      // const updatedImages2 = [...imageData];
      updatedImages.splice(index, 1);

      setImageCataLogueImage(updatedImages);

      // setImageData(updatedImages2)
      return [...state, imageName];
    });
  };

  if (isLoading) {
    return <PagesIndex.Loader />;
  } else {
    return (
      <>
        <Index.Box className="flex-all admin-title-box">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title admin-buyer-title"
              component="h3"
              variant="h3"
            >
              {row ? "Edit" : "Add"} Product
            </Index.Typography>
          </Index.Box>
          <Index.Box className="view-btn-main border-btn-main">
            <PagesIndex.BlueborderButton
              className="cancel-btn blue-border-btn"
              btnLabel="Back"
              onClick={backBtn}
            />
          </Index.Box>
        </Index.Box>

        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          {({
            handleSubmit,
            values,
            handleBlur,
            handleChange,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              {console.log(errors, "errors")}
              {console.log(values, "values")}
              <Index.Box className="common-grid-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xxs: 2, xs: 2, sm: 2, md: 2, lg: 4 }}
                    className="login-row"
                  >
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Product Name
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <Index.TextField
                            className="comman-form-control"
                            placeholder=""
                            type="text"
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                          />
                          {errors.name && touched.name && (
                            <p className="error-text">{errors.name}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Quantity
                        </Index.FormHelperText>
                        <Index.Box className="product-select-qty">
                          <Index.Button
                            className="product-select-qty-btn"
                            onClick={() => {
                              if (values.quantity > 0) {
                                setFieldValue(
                                  "quantity",
                                  Number(values.quantity) - 1
                                );
                              }
                            }}
                          >
                            -
                          </Index.Button>
                          <Index.Input
                            // placeholder=""
                            type="number"
                            name="quantity"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.quantity}
                          />

                          <Index.Button
                            className="product-select-qty-btn"
                            onClick={() => {
                              setFieldValue(
                                "quantity",
                                Number(values.quantity) + 1
                              );
                            }}
                          >
                            +
                          </Index.Button>
                        </Index.Box>
                        {errors.quantity && touched.quantity && (
                          <p className="error-text">{errors.quantity}</p>
                        )}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Specification
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <CKEditor
                            editor={ClassicEditor}
                            name="specification"
                            data={values.specification}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              const strippedData = data.replace(
                                /<\/?[^>]+(>|$)/g,
                                ""
                              );
                              const words = strippedData.trim().split(/\s+/);
                              const limitedContent = words
                                .slice(0, 100)
                                .join(" ");

                              handleChange({
                                target: {
                                  name: "specification",
                                  value: data,
                                },
                              });
                            }}
                          />
                          {errors.specification && touched.specification && (
                            <p className="error-text">{errors.specification}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Description
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <CKEditor
                            editor={ClassicEditor}
                            name="description"
                            data={values.description}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              const strippedData = data.replace(
                                /<\/?[^>]+(>|$)/g,
                                ""
                              );
                              const words = strippedData.trim().split(/\s+/);
                              const limitedContent = words
                                .slice(0, 100)
                                .join(" ");

                              handleChange({
                                target: {
                                  name: "description",
                                  value: data,
                                },
                              });
                            }}
                          />
                          {errors.description && touched.description && (
                            <p className="error-text">{errors.description}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Brand
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <PagesIndex.CheckBoxDropdown
                            options={brandData}
                            handleChange={handleChange}
                            value={values.brand}
                            name="brand"
                            multiple
                          />

                          {errors.brand && touched.brand && (
                            <p className="error-text">{errors.brand}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Dimension
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <Dropdown
                            options={dimensionsData}
                            handleChange={handleChange}
                            value={values.dimention}
                            name="dimention"
                          />
                          {errors.dimention && touched.dimention && (
                            <p className="error-text">{errors.dimention}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Thickness
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <PagesIndex.CheckBoxDropdown
                            options={thicknessData}
                            handleChange={handleChange}
                            value={values.thickness}
                            name="thickness"
                          />
                          {errors.thickness && touched.thickness && (
                            <p className="error-text">{errors.thickness}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Type
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <PagesIndex.CheckBoxDropdown
                            options={typeData}
                            handleChange={handleChange}
                            value={values.type}
                            name="type"
                          />
                          {errors.type && touched.type && (
                            <p className="error-text">{errors.type}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Grad
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <PagesIndex.CheckBoxDropdown
                            options={gradsData}
                            handleChange={handleChange}
                            value={values.grad}
                            name="grad"
                          />
                          {errors.grad && touched.grad && (
                            <p className="error-text">{errors.grad}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Application
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <PagesIndex.CheckBoxDropdown
                            options={applicationData}
                            handleChange={handleChange}
                            value={values.application}
                            name="application"
                          />
                          {errors.application && touched.application && (
                            <p className="error-text">{errors.application}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Payment
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <Dropdown
                            options={paymentData}
                            handleChange={handleChange}
                            value={values.payment}
                            name="payment"
                          />

                          {errors.payment && touched.payment && (
                            <p className="error-text">{errors.payment}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Category
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          {console.log(
                            "values.productCategoryId",
                            values.productCategoryId
                          )}
                          <PagesIndex.CheckBoxDropdown
                            options={categoryData}
                            handleChange={handleChange}
                            value={values.productCategoryId}
                            name="productCategoryId"
                          />
                          {errors.productCategoryId &&
                            touched.productCategoryId && (
                              <p className="error-text">
                                {errors.productCategoryId}
                              </p>
                            )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Sub Category
                        </Index.FormHelperText>
                        <Index.Box className="auth-input-main">
                          <PagesIndex.CheckBoxDropdown
                            options={subCategoryData}
                            handleChange={handleChange}
                            value={values.productSubCategoryId}
                            name="productSubCategoryId"
                          />
                          {errors.productSubCategoryId &&
                            touched.productSubCategoryId && (
                              <p className="error-text">
                                {errors.productSubCategoryId}
                              </p>
                            )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.FormHelperText className="comman-form-label">
                          Images
                        </Index.FormHelperText>
                        <Index.Box className="upload-img-file-modal">
                          <Index.Button
                            component="label"
                            variant="contained"
                            className="upload-label-details"
                          >
                            <Index.Box className="upload-main-content">
                              <img
                                src={PagesIndex.Png.cloud}
                                className="upload-cloud"
                                alt="cloud"
                              ></img>
                            </Index.Box>
                            <Index.Typography className="browse-cloud-text">
                              Browse Files to upload
                            </Index.Typography>
                            <Index.Typography className="browse-cloud-accepted">
                              (accepted JPG & PNG only)
                            </Index.Typography>
                            <VisuallyHiddenInput
                              type="file"
                              multiple
                              name="productImages"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                const files = e.target.files;
                                const filesArray = [];
                                let imgNames = [];

                                for (let i = 0; i < files.length; i++) {
                                  const file = files[i];

                                  filesArray.push(file);
                                  imgNames.push(file.name);
                                }

                                setFieldValue("productImages", filesArray);

                                if (singleProduct?._id) {
                                  let img = [
                                    ...imageCataLogueImage,
                                    ...filesArray,
                                  ];
                                  // let img2 = [
                                  //   ...imageData,
                                  //   ...filesArray,
                                  // ];
                                  setImageCataLogueImage(img);
                                  // setImageData(img2)
                                } else {
                                  setImageCataLogueImage(filesArray);
                                  // setImageData(filesArray)
                                }
                              }}
                            />
                          </Index.Button>
                          {errors.productImages && touched.productImages && (
                            <p className="error-text">{errors.productImages}</p>
                          )}
                        </Index.Box>
                        <Index.Box className="upload-mt-details">
                          {imageCataLogueImage?.map((val, index) => {
                            let imageName;
                            if (val?.name) {
                              imageName = val?.name;
                            } else {
                              imageName = val;
                            }
                            return (
                              <>
                                <Index.Box className="upload-document-show">
                                  <Index.Box className="upload-document-card">
                                    <Index.Box className="file-icon-name">
                                      <img
                                        src={
                                          image
                                            ? `http://194.233.77.156:3042/images/${val}`
                                            : val
                                            ? URL.createObjectURL(val)
                                            : "-"
                                        }
                                        className="description-upload"
                                        alt="desc"
                                      ></img>
                                      <Index.Typography className="file-payment-name">
                                        {imageName ? imageName : "-"}
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Button
                                      className="btn-delete-file"
                                      disableRipple
                                    >
                                      <img
                                        src={PagesIndex.Png.deleteupload}
                                        className="delete-details"
                                        alt="delete"
                                        onClick={() =>
                                          handleImageDelete(imageName, index)
                                        }
                                      ></img>
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </>
                            );
                          })}

                          {/* {imageData?.map((val, index) => {
                            let imageName;
                            if (val?.name) {
                              imageName = val?.name;
                            } else {
                              imageName = val;
                            }
                            return (
                              <>
                                <Index.Box className="upload-document-show">
                                  <Index.Box className="upload-document-card">
                                    <Index.Box className="file-icon-name">
                                      <img
                                        src={
                                          image
                                            ? `http://194.233.77.156:3042/images/${val}`
                                            : URL.createObjectURL(val)
                                        }
                                        className="description-upload"
                                        alt="desc"
                                      ></img>
                                      <Index.Typography className="file-payment-name">
                                        {imageName ? imageName : "-"}
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Button
                                      className="btn-delete-file"
                                      disableRipple
                                    >
                                      <img
                                        src={PagesIndex.Png.deleteupload}
                                        className="delete-details"
                                        alt="delete"
                                        onClick={() =>
                                          handleImageDelete(imageName, index)
                                        }
                                      ></img>
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </>
                            );
                          })} */}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="admin-footer-btn-box">
                <PagesIndex.BlueborderButton
                  className="cancel-btn blue-border-btn"
                  btnLabel="Cancel"
                  onClick={backBtn}
                />
                <PagesIndex.BlueFillButton
                  className="view-order-btn blue-fill-btn"
                  // btnLabel="Submit"
                  btnLabel={
                    !loading ? (
                      "Submit"
                    ) : (
                      <>
                        <Index.CircularProgress
                          sx={{ color: "white", mr: 1 }}
                          size={24}
                        />
                      </>
                    )
                  }
                  type="submit"
                  disabled={loading}
                />
              </Index.Box>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
