import React, { useState } from "react";
import PagesIndex from "../../PagesIndex";
import Index from "../../Index";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";
import { toast } from "react-toastify";
// for modal design

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   bgcolor: "background.paper",
//   boxShadow: 24,
// };

export default function ResetPassword({
  openResetUser,
  handleCloseResetUser,
  email,
  handleCloseForgotUser,
  handleCloseUserOtp,
  openLoginModal,
  handleClose,
  verifyEmail,
}) {
  console.log(email, 18);
  const [showPassword, setShowPassword] = React.useState(false);
  // const [showConfirmPassword, setConfirmShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const handleClickConfirmShowPassword = () =>
  //   setConfirmShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Confirm password
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const [loading, setLoading] = useState(false);

  // ResetUser modal
  // const [openResetUser, setOpenResetUser] = useState(false);
  // const handleOpenResetUser = () => setOpenResetUser(true);
  // const handleCloseResetUser = () => setOpenResetUser(false);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Please enter password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf(
        [Yup.ref("password")],
        "New password and confirmed passwords do not match"
      ),
  });
  const handleFormSubmit = async (values, { resetForm }) => {
    console.log(values, 60);
    setLoading(true);
    const data = {
      masterField: verifyEmail,
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    await DataService.post(Api.User.Auth.RESET_PASSWORD, data)
      .then((res) => {
        console.log(res?.data, 722);
        toast.success(res?.data?.message);
        if (res?.data?.status === 200) {
          //   handleCloseResetUser();
          // handleCloseResetUser();
          // handleCloseUserOtp();
          // handleCloseForgotUser();
          // openLoginModal();
          handleClose();
          resetForm();
          setLoading(false);
        }
      })
      .catch((errors) => {
        console.log(errors, 74);
        toast.error(errors?.response?.data?.message);
        resetForm();
        setLoading(false);
      });
  };

  return (
    <>
      {/* <PagesIndex.OrangeFillButton btnLabel="Go to cart" onClick={handleOpenResetUser} className="orange-fill-btn go-tocart-btn" /> */}
      {/* <Index.Modal
        open={openResetUser}
        onClose={handleCloseResetUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-common-main"
      >  */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            {/* <Index.Box
              sx={style}
              className="login-modal-inner-main auth-modal-inner modal-common-style"
            > */}
            <Index.Box className="cus-scrollbar modal-hgt-scroll">
              <Index.Box className="modal-header">
                <Index.Typography className="auth-modal-title">
                  Reset Password
                </Index.Typography>
                <img
                  src={PagesIndex.Svg.closeicon}
                  className="modal-close-icon"
                  onClick={handleClose}
                />
              </Index.Box>
              <Index.Box className="modal-body">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xxs: 2, xs: 2, sm: 2, md: 3, lg: 4 }}
                    className="login-row"
                  >
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.Box className="auth-input-main">
                          <Index.OutlinedInput
                            className="comman-form-control"
                            placeholder="New password"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={values?.password}
                            // onChange={handleChange}
                            onChange={(e) => {
                              setFieldValue("password", e.target.value.trim());
                            }}
                            onBlur={handleBlur}
                            inputProps={{ maxlength: 16 }}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>
                        {errors.password && touched.password && (
                          <p className="error-text">{errors.password}</p>
                        )}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xxs: "span 12",
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="comman-form-group">
                        <Index.Box className="auth-input-main">
                          <Index.OutlinedInput
                            className="comman-form-control"
                            placeholder="Confirm new password"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={values?.confirmPassword}
                            // onChange={handleChange}
                            onChange={(e) => {
                              setFieldValue(
                                "confirmPassword",
                                e.target.value.trim()
                              );
                            }}
                            onBlur={handleBlur}
                            inputProps={{ maxlength: 16 }}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownConfirmPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>
                        {errors.confirmPassword && touched.confirmPassword && (
                          <p className="error-text">{errors.confirmPassword}</p>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="modal-footer">
                <Index.Box className="forgot-btn-footer">
                  <Index.Box className="modal-auth-btn-main">
                    <PagesIndex.OrangeFillButton
                      btnLabel="Submit"
                      className="orange-fill-btn auth-modal-btn"
                      // onClick={handleCloseResetUser}
                      type="submit"
                      disabled={loading}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {/* </Index.Box> */}
          </Form>
        )}
      </Formik>
      {/* </Index.Modal> */}
    </>
  );
}
